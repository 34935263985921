/**
 * Copyright Motili Inc., 2017 All Rights Reserved
 */

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'common/components/Alert';

import ResetPassword from './ResetPassword';
import AuthStore from '../../stores/AuthStore';
import { resetPassword as AuthServiceResetPassword } from '../../services/AuthService';
/**
 * Login
 *
 */
const ResetPasswordContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [passwordSuccessfullyReset, setPasswordSuccessfullyReset] =
        useState(false);
    const [loading, setLoading] = useState(AuthStore.getState().loading);
    const [error, setError] = useState('');

    useEffect(() => {
        const unlisten = AuthStore.listen(onChange);
        return () => unlisten();
    }, []);

    const onChange = state => {
        setLoading(state.loading);
    };

    return (
        <div>
            <ResetPassword
                loading={loading}
                password={password}
                confirmationPassword={confirmationPassword}
                error={error}
                passwordSuccessfullyReset={passwordSuccessfullyReset}
                handlePasswordChange={password => setPassword(password)}
                handleConfirmationPasswordChange={password =>
                    setConfirmationPassword(password)
                }
                handleResetPassword={e => handleResetPassword(e)}
                handleGoToAccountLogin={() => navigate('/login')}
            />
        </div>
    );

    function handleResetPassword(e) {
        e.preventDefault();

        if (!password && !confirmationPassword) {
            return setError('No password specified.');
        }

        if (password !== confirmationPassword) {
            return setError('Passwords do not match.');
        }
        setError(null);

        const resetPasswordData = {
            password,
            confirmationPassword,
            token: accessToken,
        };

        return AuthServiceResetPassword(resetPasswordData)
            .then(response => {
                setPasswordSuccessfullyReset(true);
                return response;
            })
            .catch(errObject => {
                setError(errObject.message);
                if (errObject.message === 'Invalid token') {
                    Alert.error(
                        'There was an error processing the request, please contact support@motili.com if you need assistance logging into your account.'
                    );
                }
            });
    }
};

export default ResetPasswordContainer;
