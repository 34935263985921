/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { State } from 'common/types/filters';
import * as FilterAction from 'common/actions/redux/FilterAction';
import moment from 'moment-timezone';

const initialState: State = {
    activeFilters: [],
    filterId: 101,
    advancedFilterId: 501,
    activePage: 1,
    orders: [{ property: 'created', sortBy: 'DESC' }],
    updated: moment().format('x'),
};

const adminFinanceSyncQueueListFilterSlice = createSlice({
    name: 'adminFinanceSyncQueueListFilter',
    initialState,
    reducers: {
        ...FilterAction,
        initPageAndSort: (state, action) => ({
            ...state,
            ...action.payload,
            activePage: 1,
        }),
        init: () => ({
            ...initialState,
            updated: moment().format('x'),
        }),
    },
});

export const actions = adminFinanceSyncQueueListFilterSlice.actions;
export default adminFinanceSyncQueueListFilterSlice.reducer;
