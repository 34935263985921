/**
 * @license
 * @copyright Copyright Motili Inc., 2020 All Rights Reserved
 */

import * as ApiService from './ApiService';

export function fetchUtilityAccessTokenByUUID(clientId, UUID) {
    return ApiService.get(
        false,
        `Clients/${clientId}/fetchUtilityAccessTokenByUUID/${UUID}`
    );
}

export function createDraftDocument(draft, accessToken) {
    return ApiService.post(true, 'Drafts', draft, {}, false, accessToken);
}

export function initWorkOrderRequestForm(id, uuid) {
    return ApiService.get(
        false,
        `Clients/${id}/initWorkOrderRequestForm/${uuid}`
    );
}

export function submitWorkOrderRequestForm(data, accessToken) {
    return ApiService.post(
        true,
        `Drafts/submitWorkOrderRequestForm`,
        data,
        {},
        false,
        accessToken
    );
}
