/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewedJobs } from 'common/types';

interface RecentlyViewedJobsState {
    viewedJobs: ViewedJobs;
}

const initialState: RecentlyViewedJobsState = {
    viewedJobs: {},
};

const RecentlyViewedJobsCreationSlice = createSlice({
    name: 'recentlyViewedJobsCreation',
    initialState,
    reducers: {
        addViewedJob(state, action: PayloadAction<ViewedJobs>) {
            state.viewedJobs = { ...state.viewedJobs, ...action.payload };
        },
        resetRecentlyViewedJobsStore() {
            return initialState;
        },
    },
});

export const { addViewedJob, resetRecentlyViewedJobsStore } =
    RecentlyViewedJobsCreationSlice.actions;

export default RecentlyViewedJobsCreationSlice.reducer;
