/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { State } from 'common/types/filters';
import * as FilterAction from 'common/actions/redux/FilterAction';
import moment from 'moment-timezone';

const getInitialOrder = () => {
    return [{ property: 'created', sortBy: 'DESC' }];
};

const initialState: State = {
    activeFilters: [],
    filterId: 101,
    advancedFilterId: 501,
    activePage: 1,
    orders: getInitialOrder(),
    updated: moment().format('x'),
};



const adminFinanceTransactionTaxHistoryListFilterSlice = createSlice({
    name: 'adminFinanceTransactionTaxHistoryListFilterSlice',
    initialState,
    reducers: {
        ...FilterAction,
        initPageAndSort: (state, action) => ({
            ...state,
            activePage: 1,
            orders: getInitialOrder(),
            ...action.payload,
        }),
        init: () => ({
            ...initialState,
            updated: moment().format('x'),
        }),
        clearAll: () => ({
            ...initialState,
            updated: moment().format('x'),
        }),
    },
});

export const actions = adminFinanceTransactionTaxHistoryListFilterSlice.actions;
export default adminFinanceTransactionTaxHistoryListFilterSlice.reducer;
