/**
 * Copyright Motili Inc., 2019 All Rights Reserved
 */

import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'common/components/Alert';

import { ServiceWorkerContext } from 'useServiceWorker';
import { UserTokenContext } from 'useUserToken';
import { initStoreAfterLogin } from 'common/stores/redux/store';
import AuthStore from '../../stores/AuthStore';
import AuthActions from '../../actions/AuthActions';

import {
    login as AuthServiceLogin,
    forgotPassword as AuthServiceForgotPassword,
} from '../../services/AuthService';

import Login from './Login';

export default function LoginContainer() {
    const serviceWorker = useContext(ServiceWorkerContext);
    const userToken = useContext(UserTokenContext);
    return (
        <LoginContainerComponent
            serviceWorker={serviceWorker}
            userToken={userToken}
        />
    );
}

/**
 * Login
 *
 */
const LoginContainerComponent = ({ serviceWorker, userToken }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [loading, setLoading] = useState(AuthStore.getState().loading);
    const [authenticated, setAuthenticated] = useState(
        AuthStore.getState().authenticated
    );
    const [forgotPassword, setForgotPassword] = useState(false);

    useEffect(() => {
        const unlisten = AuthStore.listen(onChange);
        return () => unlisten();
    }, []);

    const onChange = state => {
        setLoading(state.loading);
        setAuthenticated(state.authenticated);
    };

    if (authenticated) {
        return <Navigate to={{ pathname: '/', search: location?.search }} />;
    }

    return (
        <Login
            handleLogin={handleLogin}
            login={handleLogin}
            email={email}
            password={password}
            handleEmailChange={email => setEmail(email)}
            handlePasswordChange={password => setPassword(password)}
            loading={loading}
            forgotPasswordEmail={forgotPasswordEmail}
            forgotPassword={forgotPassword}
            handleForgotPasswordEmailChange={forgotPasswordEmail =>
                setForgotPasswordEmail(forgotPasswordEmail)
            }
            handleForgotPasswordLinkClicked={handleForgotPasswordLinkClicked}
            handleForgotPassword={handleForgotPassword}
        />
    );

    async function handleLogin(e) {
        e.preventDefault();
        setLoading(true);
        try {
            // check if new version available
            const { updateServiceWorker } = serviceWorker;
            const { setLoggedIn } = userToken;
            await setLoggedIn(false);
            const loginResult = await AuthServiceLogin({
                email: email.toLowerCase().trim(),
                password,
            });
            if (loginResult.changePassword === true) {
                navigate('/updatePassword');
            } else {
                // call service worker update after login success
                if (updateServiceWorker) {
                    // update service worker
                    await updateServiceWorker(true);
                }
                AuthActions.fetchedToken(loginResult.token);
                await setLoggedIn(true);
                initStoreAfterLogin();
            }
        } catch (errObject) {
            AuthActions.resetAuthentication();
            handleErrorAlert(errObject);
        }
    }

    function handleForgotPassword(e) {
        e.preventDefault();
        return AuthServiceForgotPassword({
            email: forgotPasswordEmail,
        })
            .then(response => {
                AuthActions.resetAuthentication();
                Alert.success(
                    'Password reset instructions have been sent to your email address.',
                    {
                        autoClose: 3000,
                    }
                );
                return response;
            })
            .catch(errObject => {
                AuthActions.resetAuthentication();
                handleErrorAlert(errObject);
                return errObject;
            });
    }

    function handleForgotPasswordLinkClicked(boolean) {
        setForgotPassword(boolean);
    }
};

LoginContainerComponent.propTypes = {
    serviceWorker: PropTypes.object,
    userToken: PropTypes.object,
};

function handleErrorAlert(errObject) {
    if (errObject) {
        if (errObject.statusCode === 401) {
            return Alert.error(
                'The provided email and password were not recognized, please try again. Please contact support@motili.com if you need assistance logging into your account.',
                {
                    autoClose: 3000,
                }
            );
        }
        return Alert.error(
            'There was an error processing the request, please contact support@motili.com if you need assistance logging into your account.',
            {
                autoClose: 3000,
            }
        );
    }
}
