/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';

import { ping } from '../../../services/HealthCheckService';
import * as AuthService from '../../../services/AuthService';
import Config from '../../../../Config';
import MetaStoreApi from './MetaStoreApi';

const metaStoreInitialState = {
    loading: true,
    initialized: false,
    error: null,
    activeUser: '',
    s2Version: '',
    webVersion: '',
};

const startLoading = state => {
    state.loading = true;
};

const loadingFailed = (state, action) => {
    state.loading = false;
    state.error = action.payload;
};

const metaStoreSlice = createSlice({
    name: 'metaStore',
    initialState: metaStoreInitialState,
    reducers: {
        initStart: startLoading,
        initSuccess: (state, action) => {
            state.activeUser = action.payload.activeUser;
            state.s2Version = action.payload.s2Version;
            state.webVersion = action.payload.webVersion;
            state.initialized = true;
            state.loading = false;
            state.error = null;
        },
        initFailure: loadingFailed,
        resetStore: state => {
            state.initialized = false;
        },
    },
});
export default metaStoreSlice.reducer;

export const { initStart, initSuccess, initFailure, resetStore } =
    metaStoreSlice.actions;

export const initMetaStore = () => async (dispatch, getState) => {
    try {
        const currentState = getState().metaStore;
        const s2Info = await ping();
        const currentS2Version = s2Info?.version;
        const currentWebVersion = Config.version;
        const requireReload =
            currentState.s2Version !== currentS2Version ||
            currentState.webVersion !== currentWebVersion;
        const activeUser = determineActiveUser(currentState);
        const isNewUser = activeUser !== currentState.activeUser;

        if (!requireReload && !isNewUser && currentState.initialized) {
            return;
        }
        dispatch(initStart());

        if (!currentState.initialized || requireReload || isNewUser) {
            dispatch(MetaStoreApi.util.resetApiState());
        }

        dispatch(
            initSuccess({
                activeUser,
                s2Version: currentS2Version,
                webVersion: currentWebVersion,
            })
        );
    } catch (err) {
        dispatch(initFailure(err.message || err));
    }
};

function determineActiveUser(currentState) {
    if (AuthService.isMotiliAccount()) return 'MOTILI';
    if (AuthService.isContractorAccount()) return 'CONTRACTOR';
    if (AuthService.isClientAccount()) return 'CLIENT';
    return currentState.activeUser;
}
