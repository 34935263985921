/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import { Button, Image } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import FormEmailInput from '../../components/form/FormEmailInput-deprecated';
import FormPasswordInput from '../../components/form/FormPasswordInput';

import background from '../../../images/splash2.png';
import logo from '../../../images/Motili-Logo-White.svg';

export default function Login(props) {
    return (
        <div style={styles.background}>
            <div style={styles.formContainer}>
                <div style={{ flex: 0.45 }}>
                    <div className='login-form-top'>
                        <Image
                            src={logo}
                            responsive
                            className='login-form-logo'
                            style={{ height: 150 }}
                        />
                    </div>
                    <div className='login-form-bottom'>
                        <TransitionGroup>
                            {props.forgotPassword ? (
                                <CSSTransition
                                    key='login'
                                    timeout={300}
                                    classNames='example'
                                >
                                    <ForgotPasswordForm {...props} />
                                </CSSTransition>
                            ) : (
                                <CSSTransition
                                    key='forgot'
                                    timeout={300}
                                    classNames='example'
                                >
                                    <LoginForm {...props} />
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}
Login.propTypes = {
    forgotPassword: PropTypes.bool,
};

Login.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleForgotPassword: PropTypes.func.isRequired,
    handleForgotPasswordLinkClicked: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    handleForgotPasswordEmailChange: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    forgotPasswordEmail: PropTypes.string.isRequired,
    forgotPassword: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};

function LoginForm(props) {
    return (
        <div>
            <form key='login' onSubmit={props.handleLogin}>
                <div style={{ marginBottom: 5 }}>
                    <FormEmailInput
                        noLabel
                        value={props.email}
                        bsSize='large'
                        onChange={e => {
                            props.handleEmailChange(e.target.value);
                        }}
                    />
                </div>
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        bsSize='large'
                        value={props.password}
                        onChange={e => {
                            props.handlePasswordChange(e.target.value);
                        }}
                    />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <Button
                        bsStyle='primary'
                        bsSize='large'
                        type='submit'
                        block
                        disabled={props.loading}
                    >
                        {props.loading ? 'Signing In ...' : 'Login'}
                    </Button>
                </div>
                <div
                    style={styles.textLink}
                    onClick={() => props.handleForgotPasswordLinkClicked(true)}
                >
                    Forgot password?
                </div>
            </form>
        </div>
    );
}
LoginForm.propTypes = {
    loading: PropTypes.bool,
    email: PropTypes.string,
    password: PropTypes.string,
    handleEmailChange: PropTypes.func,
    handlePasswordChange: PropTypes.func,
    handleLogin: PropTypes.func,
    handleForgotPasswordLinkClicked: PropTypes.func,
};

LoginForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    handleForgotPasswordLinkClicked: PropTypes.func.isRequired,
};

function ForgotPasswordForm({
    handleForgotPassword,
    forgotPasswordEmail,
    handleForgotPasswordEmailChange,
    loading,
    handleForgotPasswordLinkClicked,
}) {
    return (
        <form
            key='forgotPassword'
            onSubmit={handleForgotPassword}
            style={{ marginTop: 20 }}
        >
            <div style={{ marginBottom: 5 }}>
                <FormEmailInput
                    noLabel
                    value={forgotPasswordEmail}
                    bsSize='large'
                    onChange={e => {
                        handleForgotPasswordEmailChange(e.target.value);
                    }}
                />
            </div>
            <div style={{ marginBottom: 10 }}>
                <Button
                    bsStyle='primary'
                    bsSize='large'
                    type='submit'
                    block
                    disabled={loading}
                >
                    {loading ? 'Submitting ...' : 'Reset Password'}
                </Button>
            </div>

            <div
                style={styles.textLink}
                onClick={() => handleForgotPasswordLinkClicked(false)}
            >
                Return to account login
            </div>
        </form>
    );
}
ForgotPasswordForm.propTypes = {
    loading: PropTypes.bool,
    forgotPasswordEmail: PropTypes.string,
    handleForgotPasswordEmailChange: PropTypes.func,
    handleForgotPassword: PropTypes.func,
    handleForgotPasswordLinkClicked: PropTypes.func,
};

ForgotPasswordForm.propTypes = {
    handleForgotPassword: PropTypes.func.isRequired,
    handleForgotPasswordEmailChange: PropTypes.func.isRequired,
    forgotPasswordEmail: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    handleForgotPasswordLinkClicked: PropTypes.func.isRequired,
};

const styles = {
    background: {
        height: '100vh',
        minWidth: 768,
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
    },
    formContainer: {
        flexDirection: 'row',
        display: 'flex',
        flex: 0.75,
        justifyContent: 'space-around',
    },
    textLink: {
        cursor: 'pointer',
        color: 'white',
        fontSize: 14,
    },
};
