export default {
    City: {
        singular: 'Municipality',
        plural: 'Municipalities',
    },
    State: {
        singular: 'Province/Territory',
        plural: 'Provinces/Territories',
    },
    'Zip Code': {
        singular: 'Postal Code',
        plural: 'Postal Codes',
    },
};
