/**
 * Copyright Motili Inc., 2023 All Rights Reserved
 */

import { Suspense } from 'react';
import { theme, LoadingSpinner } from '@motili/ui';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import {
    createHashRouter,
    createRoutesFromElements,
    RouterProvider,
    Routes,
    Route,
} from 'react-router-dom';

import Alert from 'common/components/Alert';
import reduxStore from './common/stores/redux/store';
import Login from './common/views/login/LoginContainer';
import ChangePassword from './common/views/change-password/ChangePasswordContainer';
import ResetPassword from './common/views/reset-password/ResetPasswordContainer';
import WorkRequestForm from './common/views/forms/WorkRequestFormContainer';
import MobileRouter from './common/MobileRouter';
import AuthenticatedRouter from './common/AuthenticatedRouter';
import GlobalHistory from './history';

import './style/sass/index.scss';

// Specific routes are 'lifted' to utilize data-router features like useBlocker
const router = createHashRouter(
    createRoutesFromElements(<Route path='*' element={<Root />} />)
);

// Normal route tree lives here
function Root() {
    return (
        <>
            <GlobalHistory />
            <Routes>
                {/* Register public routes */}
                <Route path='/login' element={<Login />} />
                <Route path='/updatePassword' element={<ChangePassword />} />
                <Route path='/resetpassword' element={<ResetPassword />} />
                <Route
                    path='/request-service/:clientId/:uuid'
                    element={<WorkRequestForm />}
                />
                <Route path='/m' element={<MobileRouter />} />
                {/* All nested routes are maintained in AuthenticatedRouter */}
                <Route path='/*' element={<AuthenticatedRouter />} />
            </Routes>
            <Alert />
        </>
    );
}

function App() {
    const persistor = persistStore(reduxStore);
    return (
        <Suspense
            fallback={<LoadingSpinner fullscreen color={theme.colors.bgMain} />}
        >
            <Helmet>
                <title>Motili</title>
            </Helmet>
            <ReduxProvider store={reduxStore}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <RouterProvider router={router} />
                    </ThemeProvider>
                </PersistGate>
            </ReduxProvider>
        </Suspense>
    );
}

export default App;
