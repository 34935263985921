/**
 * @license
 * @copyright Copyright Motili Inc., 2019 All Rights Reserved
 */

import {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import * as serviceWorker from './serviceWorkerRegistration';

export const ServiceWorkerContext = createContext({});

export const ServiceWorkerProvider = ({ children }) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);
    const [isAutoUpdate, setIsAutoUpdate] = useState(false);
    const [registration, setRegistration] = useState(undefined);
    const [pathname, setPathname] = useState('');

    const reloadPage = useCallback(() => {
        /**
         * Wait for 5 seconds before reload
         */
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }, []);

    useEffect(() => {
        // check service worker update on route change
        if (registration) {
            registration.update().catch(err => {
                console.error('error during try to update service worker', err);
            });
            if (pathname === '/login') {
                setIsAutoUpdate(true); // enable auto update if change to login page
            } else {
                /**
                 * Wait for 10 seconds before disable auto update
                 */
                setTimeout(() => {
                    setIsAutoUpdate(false);
                }, 10000);
            }
        }
    }, [registration, pathname]);

    useEffect(() => {
        serviceWorker.register({
            onUpdate: _registration => {
                setWaitingServiceWorker(_registration.waiting);
            },
            onWaiting: _registration => {
                setWaitingServiceWorker(_registration.waiting);
            },
            onReady: _registration => {
                setRegistration(_registration);
            },
        });
    }, []);

    useEffect(() => {
        // We setup an event listener to automatically reload the page
        // after the Service Worker has been updated, this will trigger
        // on all the open tabs of our application, so that we don't leave
        // any tab in an inconsistent state
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    console.log('Reload Page');
                    window.location.reload();
                }
            });
        }
    }, [waitingServiceWorker]);

    // Handle auto update
    useEffect(() => {
        if (waitingServiceWorker) {
            setUpdateAvailable(!isAutoUpdate);
            if (isAutoUpdate) {
                waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                reloadPage(); // Reload page to make sure service worker updated
            }
        }
    }, [isAutoUpdate, waitingServiceWorker, reloadPage]);

    const value = useMemo(
        () => ({
            isUpdateAvailable,
            updateAssets: () => {
                if (waitingServiceWorker) {
                    // We send the SKIP_WAITING message to tell the Service Worker
                    // to update its cache and flush the old one
                    console.log('Sending SKIP_WAITING');
                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                    reloadPage(); // Reload page to make sure service worker updated
                }
            },
            updateServiceWorker: autoUpdate => {
                setIsAutoUpdate(autoUpdate);
                if (registration) {
                    registration.update().catch(err => {
                        console.error(
                            'error during try to update service worker',
                            err
                        );
                    });
                }
                if (waitingServiceWorker && autoUpdate) {
                    /**
                     * Auto update if waiting service worker installed
                     */
                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                    reloadPage(); // Reload page to make sure service worker updated
                }
            },
            registration,
            setPathname,
        }),
        [isUpdateAvailable, waitingServiceWorker, registration, reloadPage]
    );

    return (
        <ServiceWorkerContext.Provider value={value}>
            {children}
        </ServiceWorkerContext.Provider>
    );
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
    return useContext(ServiceWorkerContext);
};
