/**
 * Copyright Motili Inc., 2024 All Rights Reserved
 */

import { post } from './ApiService';

export function ping() {
    return new Promise((resolve, reject) => {
        post(false, 'ping')
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}
