/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import {
    findById,
    activateTeamMember as atm,
    deactivateTeamMember as dtm,
    createTeamMember as ctm,
    updateTeamMember as utm,
} from '../../common/services/ClientService';

export function getCompany(id) {
    const query = {
        filter: {
            include: [
                {
                    relation: 'address',
                    scope: {
                        fields: [
                            'rawAddress',
                            'stdAddress',
                            'stdDeliveryLine',
                            'stdLastLine',
                        ],
                    },
                },
                {
                    relation: 'billingAddress',
                    scope: {
                        fields: [
                            'rawAddress',
                            'stdAddress',
                            'stdDeliveryLine',
                            'stdLastLine',
                        ],
                    },
                },
                {
                    relation: 'team',
                    scope: {
                        fields: ['accountId'],
                        include: [
                            {
                                relation: 'account',
                                scope: {
                                    fields: [
                                        'id',
                                        'active',
                                        'firstName',
                                        'lastName',
                                        'timezoneId',
                                        'email',
                                        'notificationPreferences',
                                        'createdAt',
                                    ],
                                    include: [
                                        {
                                            relation: 'roles',
                                            scope: {
                                                fields: ['name', 'description'],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
                'settings',
                'clientUtilities',
            ],
            fields: [
                'id',
                'statusId',
                'name',
                'doingBusinessAs',
                'website',
                'phone',
                'fax',
                'contact',
                'addressId',
                'billingAddressId',
                'operationInCountry',
                'created',
                'updated',
            ],
        },
    };
    return findById(id, query);
}

export function activateTeamMember(id, teamMemberId) {
    return atm(id, teamMemberId).then(() => getCompany(id));
}

export function deactivateTeamMember(id, teamMemberId) {
    return dtm(id, teamMemberId).then(() => getCompany(id));
}

export function createTeamMember(id, teamMember) {
    return ctm(id, teamMember).then(() => getCompany(id));
}

export function updateTeamMember(id, teamMember) {
    return utm(id, teamMember).then(() => getCompany(id));
}
