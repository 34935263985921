/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import * as ApiService from './ApiService';

export function lookup(number, country) {
    const query = {
        number,
        country: country || 'US',
    };
    return ApiService.get(true, 'TwilioPhoneNumbers/lookup', query);
}
export function lookupWithAccessToken(number, country, accessToken) {
    const query = {
        number,
        country: country || 'US',
    };
    return ApiService.get(
        true,
        'TwilioPhoneNumbers/lookup',
        query,
        false,
        accessToken
    );
}

export function validateMobile(number, country) {
    return lookup(number, country)
        .then(response => {
            if (
                response &&
                response.carrier &&
                isMobileCarrier(response, country)
            ) {
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

export function validatePhone(number, country) {
    return lookup(number, country)
        .then(response => {
            if (response && response.carrier) {
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

export function validateNumber(number, country) {
    const query = {
        number,
        country: country || 'US',
    };
    return ApiService.get(true, 'TwilioPhoneNumbers/isValid', query)
        .then(response => response)
        .catch(error => {
            console.log(error);
            return false;
        });
}

export async function validateMobileWithAccessToken(
    number,
    accessToken,
    country
) {
    try {
        const response = await lookupWithAccessToken(
            number,
            accessToken,
            country
        );
        return isMobileCarrier(response, country);
    } catch (error) {
        console.error(error);
    }
    return false;
}

export async function validatePhoneWithAccessToken(
    number,
    accessToken,
    country
) {
    try {
        const response = await lookupWithAccessToken(
            number,
            accessToken,
            country
        );
        return response?.carrier;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function ignoreCarrier(country) {
    switch (country) {
        case 'CA':
            return true;
    }
    return false;
}

function isMobileCarrier(response, country) {
    if (!response) return false;
    return (
        ignoreCarrier(country) ||
        response?.carrier?.type === 'mobile' ||
        response?.carrier?.type === 'voip'
    );
}
