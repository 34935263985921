/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './MotiliButtonContainer.module.scss';

export default class MotiliButtonContainer extends Component {
    static propTypes = {
        children: PropTypes.node,
        styles: PropTypes.object,
    };

    static defaultProps = {};

    render() {
        return (
            <div
                className={styles.motiliButtonContainer}
                style={{ ...this.props.styles }}
            >
                {this.props.children}
            </div>
        );
    }
}
