/**
 * @license
 * @copyright Copyright Motili Inc., 2019 All Rights Reserved
 */

import { merge, isEmpty } from 'lodash';
import * as ApiService from '../../common/services/ApiService';
import {
    isMotiliEngineeringDept,
    isMotiliOwner,
} from '../../common/services/AuthService';
import { isEmail } from '../../common/utils/Validator';

const baseUrl = 'businessChannels';

export const propertyFilter = {
    include: undefined,
    fields: ['id', 'name', 'description', 'config', 'parentBusinessChannelId'],
};

/**
 * Process filter
 * @param {*} activeFilters
 * @param {*} activeQuery
 * @return {object} filter
 */
export async function processFilters(activeFilters, activeQuery) {
    const query = merge({}, activeQuery, {
        where: {
            and: [],
        },
    });

    if (!activeFilters.length) {
        return query;
    }
    const typeFilter = { or: [] };
    activeFilters.forEach(filter => {
        switch (filter.target) {
            // Search Filters
            case 'NAME':
                query.where.and.push({ name: { ilike: `%${filter.term}%` } });
                break;
            default:
        }
    });
    query.where.and.push(typeFilter);
    return query;
}

/**
 * Get businessChannels
 * @param {object} query
 * @returns {Promise<Array>}
 */
export async function getBusinessChannels(query) {
    if (query) {
        return ApiService.get(true, `${baseUrl}`, { filter: query });
    }
    return [];
}

/**
 * Get tempaltes count
 * @param {object} query
 * @returns {Promise<number>}
 */
export async function getCountOfBusinessChannel(query) {
    if (query && query.where) {
        return ApiService.get(true, `${baseUrl}/count`, {
            where: query.where,
        }).then(result => result.count);
    }
    return 0;
}

export function findHierarchy(data) {
    return ApiService.post(true, `${baseUrl}/searchHierarchy`, {
        filter: data,
    });
}
/**
 * Create template
 * @param {object} data
 * @returns {Promise<object>}
 */
export async function create(data) {
    return ApiService.post(true, `${baseUrl}`, data);
}

/**
 * Get Template
 * @param {number|string} id
 * @param {object} filter
 * @returns {Promise<object>}
 */
export async function getBusinessChannel(id, filter) {
    return ApiService.get(true, `${baseUrl}/${id}`, { filter });
}

export async function getBusinessChannelByKey(key) {
    const filter = {
        where: {
            businessChannelKey: key,
        },
    };
    const businessChannels = await ApiService.get(true, `${baseUrl}`, {
        filter,
    });
    if (businessChannels && businessChannels.length === 1) {
        return businessChannels[0];
    }
    return null;
}

export function isBusinessChannelEditable() {
    return isMotiliOwner() && isMotiliEngineeringDept();
}

export function validateActivateBusinessChannel(businessChannel) {
    if (!businessChannel || !businessChannel.config) {
        throw new Error('An error occur when activating business channel.');
    }

    const { theming } = businessChannel.config;
    if (!theming) {
        throw new Error('An error occur when activating business channel.');
    }
    const { pdf, alternativeFooters } = theming;

    /*
     * Setting tabs
     */

    if (!theming?.phoneNationalAccount) {
        throw new Error('Please enter phone on settings tab.');
    }

    if (!theming?.microSitesUrl) {
        throw new Error('Please enter micro site url on settings tab.');
    }

    if (!theming?.microSitesContactUrl) {
        throw new Error('Please enter micro site contact url on settings tab.');
    }

    if (!theming?.supportEmail || !isEmail(theming.supportEmail)) {
        throw new Error('Please enter email on settings tab.');
    }

    if (!theming?.fromEmailSender || !isEmail(theming.fromEmailSender)) {
        throw new Error('Please enter email from sender on settings tab.');
    }

    if (!theming?.logo1) {
        throw new Error('Please upload logo 1 on settings tab.');
    }

    if (!theming?.logo2) {
        throw new Error('Please upload logo 2 on settings tab.');
    }

    if (!theming?.color1) {
        throw new Error('Please specify color 1 value on settings tab.');
    }

    if (!theming?.color2) {
        throw new Error('Please specify color 2 value on settings tab.');
    }

    if (!theming.companyName) {
        throw new Error('Please enter company name on settings tab.');
    }

    /*
     * Email tabs
     */

    if (!theming.extendedLogo) {
        throw new Error('Please specify email header logo on email tab.');
    }

    if (!theming.abbreviatedLogo) {
        throw new Error('Please specify email signature logo on email tab.');
    }

    if (!theming.colors?.textOnBackground) {
        throw new Error('Please specify header background color on email tab.');
    }
    if (!theming.colors?.secondary) {
        throw new Error('Please specify text header color on email tab.');
    }
    if (!theming.colors?.background) {
        throw new Error('Please specify footer background color on email tab.');
    }

    if (!theming.companyAddress) {
        throw new Error('Please enter company address on email tab.');
    }

    if (!theming.shortenedWebAddress) {
        throw new Error('Please enter shorten web address on email tab.');
    }

    if (!theming.emailDomain) {
        throw new Error('Please enter email on email tab.');
    }

    if (!theming.phone) {
        throw new Error('Please enter phone on email tab.');
    }

    if (!theming.copyright) {
        throw new Error('Please enter copyright on email tab.');
    }

    if (alternativeFooters && alternativeFooters.length > 0) {
        for (const footer of alternativeFooters) {
            if (!footer.shortenedWebAddress) {
                throw new Error(
                    'Please enter alternative shorten web address on email tab.'
                );
            }
            if (!footer.copyright) {
                throw new Error(
                    'Please enter alternative copyright on email tab.'
                );
            }
            if (!footer.emailDomain || !isEmail(footer.emailDomain)) {
                throw new Error('Please enter alternative email on email tab.');
            }
            if (!footer.fromEmailSender || !isEmail(footer.fromEmailSender)) {
                throw new Error(
                    'Please enter alternative from sender email on email tab.'
                );
            }
            if (!footer.phone) {
                throw new Error('Please enter alternative phone on email tab.');
            }
            if (
                footer.socialMedia?.isFacebook &&
                isEmpty(footer.socialMedia?.facebook)
            ) {
                throw new Error(
                    'Please enter alternative facebook on email tab.'
                );
            }
            if (
                footer.socialMedia?.isTwitter &&
                isEmpty(footer.socialMedia?.twitter)
            ) {
                throw new Error(
                    'Please enter alternative twitter on email tab.'
                );
            }
            if (
                footer.socialMedia?.isLinkedin &&
                isEmpty(footer.socialMedia?.linkedin)
            ) {
                throw new Error(
                    'Please enter alternative linkedin on email tab.'
                );
            }
            if (
                footer.socialMedia?.isInstagram &&
                isEmpty(footer.socialMedia?.instagram)
            ) {
                throw new Error(
                    'Please enter alternative instagram on email tab.'
                );
            }
            if (
                footer.socialMedia?.isYoutube &&
                isEmpty(footer.socialMedia?.youtube)
            ) {
                throw new Error(
                    'Please enter alternative youtube on email tab.'
                );
            }
        }
    }

    /*
     * SMS tabs
     */

    if (!theming.smsMessagePrefix) {
        throw new Error('Please enter sms message prefix on sms tab.');
    }
    if (!theming.smsMessageSuffix) {
        throw new Error('Please enter sms message suffix on sms tab.');
    }
    if (!theming.smsMessageInfoPhone) {
        throw new Error('Please enter sms message info phone on sms tab.');
    }
    if (alternativeFooters && alternativeFooters.length > 0) {
        for (const footer of alternativeFooters) {
            if (!footer?.smsMessagePrefix) {
                throw new Error(
                    'Please enter alternative sms message prefix on sms tab.'
                );
            }
            if (!footer?.smsMessageSuffix) {
                throw new Error(
                    'Please enter alternative sms message suffix on sms tab.'
                );
            }
            if (!footer?.smsMessageInfoPhone) {
                throw new Error(
                    'Please enter alternative sms message info phone on sms tab.'
                );
            }
        }
    }

    /*
     * PDF tabs
     */

    if (!pdf || !pdf.logo) {
        throw new Error('Please specify pdf header logo on pdf tab.');
    }

    if (!theming.colors?.primary) {
        throw new Error('Please specify background color on pdf tab.');
    }
    if (pdf.contactEmail && !isEmail(pdf.contactEmail)) {
        throw new Error('Please enter an email on pdf tab.');
    }
    if (!pdf.contactPhone) {
        throw new Error('Please enter primary phone on pdf tab.');
    }
    // if (!pdf.aboveLineFooter) {
    //     throw new Error('Please enter message above footer on pdf tab.');
    // }

    return true;
}

/**
 * Update template
 * @param {number} id
 * @param {object} data
 * @returns {Promise<object>}
 */
export async function update(id, data) {
    return ApiService.patch(true, `${baseUrl}/${id}`, data);
}

export async function updateNotification(
    businessChanelKey,
    notificationId,
    data
) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/updateNotification/${notificationId}`,
        data
    );
}

export async function previewEmail(businessChanelKey, notificationId) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/previewEmail/${notificationId}`,
        undefined
    );
}

export async function testSendEmail(businessChanelKey, notificationId, data) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/testSendEmail/${notificationId}`,
        data
    );
}

export async function activateNotification(businessChanelKey, notificationId) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/activateNotification/${notificationId}`,
        undefined
    );
}

export async function deActivateNotification(
    businessChanelKey,
    notificationId
) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/deActivateNotification/${notificationId}`,
        undefined
    );
}

export async function activateNotificationByGroupIds(
    businessChanelKey,
    groupIds
) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/activateNotificationByGroupIds`,
        groupIds
    );
}

export async function deActivateNotificationByGroupIds(
    businessChanelKey,
    groupIds
) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/deActivateNotificationByGroupIds`,
        groupIds
    );
}

export async function activateBusinessChannel(
    businessChanelKey,
    alsoProcessChildren = false
) {
    return ApiService.post(
        true,
        `${baseUrl}/${businessChanelKey}/activate`,
        undefined,
        { alsoProcessChildren }
    );
}

export async function deActivateBusinessChannel(businessChanelKey) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/deActivate`);
}

export async function deleteBusinessChannel(businessChanelKey) {
    return ApiService.del(`${baseUrl}/${businessChanelKey}`);
}
