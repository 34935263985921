/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getBusinessChannel,
    create,
    update,
    updateNotification,
    activateNotification,
    deActivateNotification,
    activateNotificationByGroupIds,
    deActivateNotificationByGroupIds,
    getBusinessChannelByKey,
    deleteBusinessChannel as deleteBC,
    deActivateBusinessChannel as deActivate,
    activateBusinessChannel as activate,
} from '../../services/AdminBusinessChannelsService';

export const fetchBusinessChannelById = createAsyncThunk(
    'businessChannel/fetchById',
    async ({ id, query }) => {
        try {
            const response = await getBusinessChannel(id, query);
            return response;
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to Retrieve business channel for id ${id}`
            );
        }
    }
);

export const fetchTemplateBusinessChannel = createAsyncThunk(
    'businessChannel/fetchTemplateBusinessChannel',
    async () => {
        try {
            const response = await getBusinessChannelByKey('MOTILI');
            return response;
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to Retrieve template business channel (MOTILI)'
            );
        }
    }
);

export const fetchTemplateBusinessChannelByParentId = createAsyncThunk(
    'businessChannel/fetchTemplateBusinessChannelByParentId',
    async ({ parentBusinessChannelId }) => {
        try {
            const response = await getBusinessChannel(
                parentBusinessChannelId,
                null
            );
            return response;
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to Retrieve template business channel (MOTILI)'
            );
        }
    }
);

export const createBusinessChannel = createAsyncThunk(
    'businessChannel/create',
    async data => {
        try {
            const response = await create(data);
            return response;
        } catch (error) {
            if (
                error &&
                error.message &&
                error.message.includes('already exists')
            ) {
                throw Error(error.message);
            } else {
                throw Error(
                    `An Unknown Error Occurred; Unable to create a business channel (${data.name})`
                );
            }
        }
    }
);

export const updateBusinessChannel = createAsyncThunk(
    'businessChannel/update',
    async ({ id, data }) => {
        try {
            const businessChannel = await update(id, data);
            const response = await getBusinessChannel(businessChannel.id, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to update a business channel (${id})`
            );
        }
    }
);

export const updateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/updateNotification',
    async ({ businessChannelId, businessChannelKey, notificationId, data }) => {
        try {
            await updateNotification(businessChannelKey, notificationId, data);
            const response = await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to update a notification (${notificationId})`
            );
        }
    }
);

export const activateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/activateNotification',
    async ({ businessChannelId, businessChannelKey, notificationId }) => {
        try {
            await activateNotification(businessChannelKey, notificationId);
            const response = await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to activate a notification (${notificationId})`
            );
        }
    }
);

export const deActivateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/deActivateNotification',
    async ({ businessChannelId, businessChannelKey, notificationId }) => {
        try {
            await deActivateNotification(businessChannelKey, notificationId);
            const response = await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to deactivate a notification (${notificationId})`
            );
        }
    }
);

export const activateAllBusinessChannelNotification = createAsyncThunk(
    'businessChannel/activateAllNotification',
    async ({ businessChannelId, businessChannelKey, categoryGroupIds }) => {
        try {
            await activateNotificationByGroupIds(
                businessChannelKey,
                categoryGroupIds
            );
            return await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to activate group of notification'
            );
        }
    }
);

export const deActivateAllBusinessChannelNotification = createAsyncThunk(
    'businessChannel/deActivateAllNotification',
    async ({ businessChannelId, businessChannelKey, categoryGroupIds }) => {
        try {
            await deActivateNotificationByGroupIds(
                businessChannelKey,
                categoryGroupIds
            );
            return await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
        } catch (error) {
            throw Error(
                'An Unknown Error Occurred; Unable to deactivate group of notification'
            );
        }
    }
);

export const activateChildBusinessChannel = createAsyncThunk(
    'businessChannel/activateChildBusinessChannel',
    async ({ parentBusinessChannelId, childBusinessChannelKey }) => {
        try {
            await activate(childBusinessChannelKey);
            return await getBusinessChannel(parentBusinessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to activate a business channel (${childBusinessChannelKey}), ${error.message}`
            );
        }
    }
);

export const deActivateChildBusinessChannel = createAsyncThunk(
    'businessChannel/deActivateChildBusinessChannel',
    async ({ parentBusinessChannelId, childBusinessChannelKey }) => {
        try {
            await deActivate(childBusinessChannelKey);
            const response = await getBusinessChannel(parentBusinessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            if (error.statusCode === 417) {
                throw Error(
                    'An error occurred while updating the business channel status. There are clients belong to this business channel.'
                );
            }
            throw Error(
                `An Unknown Error Occurred; Unable to deactivate a business channel (${childBusinessChannelKey}), ${error.message}`
            );
        }
    }
);

export const activateBusinessChannel = createAsyncThunk(
    'businessChannel/activateBusinessChannel',
    async ({ businessChannelKey, businessChannelId, alsoProcessChildren }) => {
        try {
            await activate(businessChannelKey, alsoProcessChildren);
            return await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to activate a business channel (${businessChannelKey}), ${error.message}`
            );
        }
    }
);

export const deActivateBusinessChannel = createAsyncThunk(
    'businessChannel/deActivateBusinessChannel',
    async ({ businessChannelId, businessChannelKey }) => {
        try {
            await deActivate(businessChannelKey);
            const response = await getBusinessChannel(businessChannelId, {
                include: [
                    'notifications',
                    {
                        relation: 'parentBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                    {
                        relation: 'childrenBusinessChannel',
                        scope: {
                            include: ['notifications'],
                        },
                    },
                ],
            });
            return response;
        } catch (error) {
            if (error.statusCode === 417) {
                throw Error(
                    'An error occurred while updating the business channel status. There are clients belong to this business channel.'
                );
            }
            throw Error(
                `An Unknown Error Occurred; Unable to deactivate a business channel (${businessChannelKey}), ${error.message}`
            );
        }
    }
);

export const deleteBusinessChannel = createAsyncThunk(
    'businessChannel/deleteBusinessChannel',
    async ({ businessChannelKey }) => {
        try {
            await deleteBC(businessChannelKey);
        } catch (error) {
            throw Error(
                `An Unknown Error Occurred; Unable to delete a business channel (${businessChannelKey}), ${error.message}`
            );
        }
    }
);

const businessChannelSlice = createSlice({
    name: 'businessChannelSlice',
    initialState: {
        loading: false,
        businessChannel: null,
        templateBusinessChannel: null,
        successMessage: undefined,
        error: undefined,
    },
    reducers: {
        clearMessage: (state, action) => {
            state.successMessage = undefined;
            state.error = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchBusinessChannelById.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(fetchBusinessChannelById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(fetchBusinessChannelById.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = undefined;
        });

        builder.addCase(
            fetchTemplateBusinessChannel.pending,
            (state, action) => {
                state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            fetchTemplateBusinessChannel.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            fetchTemplateBusinessChannel.fulfilled,
            (state, action) => {
                state.templateBusinessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            fetchTemplateBusinessChannelByParentId.pending,
            (state, action) => {
                state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            fetchTemplateBusinessChannelByParentId.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            fetchTemplateBusinessChannelByParentId.fulfilled,
            (state, action) => {
                state.templateBusinessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );

        builder.addCase(createBusinessChannel.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(createBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(createBusinessChannel.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully created business channel';
        });

        builder.addCase(updateBusinessChannel.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannel.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully updated business channel';
        });

        builder.addCase(
            updateBusinessChannelNotification.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            updateBusinessChannelNotification.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            updateBusinessChannelNotification.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully update notification';
            }
        );

        builder.addCase(
            activateBusinessChannelNotification.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateBusinessChannelNotification.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateBusinessChannelNotification.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully activate notification';
            }
        );

        builder.addCase(
            deActivateBusinessChannelNotification.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateBusinessChannelNotification.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateBusinessChannelNotification.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully deactivate notification';
            }
        );

        builder.addCase(
            activateAllBusinessChannelNotification.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateAllBusinessChannelNotification.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateAllBusinessChannelNotification.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully activate notification';
            }
        );

        builder.addCase(
            deActivateAllBusinessChannelNotification.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateAllBusinessChannelNotification.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateAllBusinessChannelNotification.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully deactivate notification';
            }
        );

        builder.addCase(
            activateChildBusinessChannel.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateChildBusinessChannel.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            activateChildBusinessChannel.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage = 'Successfully activate business channel';
            }
        );

        builder.addCase(
            deActivateChildBusinessChannel.pending,
            (state, action) => {
                // state.loading = true;
                state.error = undefined;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateChildBusinessChannel.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.successMessage = undefined;
            }
        );
        builder.addCase(
            deActivateChildBusinessChannel.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage =
                    'Successfully deactivate business channel';
            }
        );

        builder.addCase(activateBusinessChannel.pending, (state, action) => {
            // state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(activateBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(activateBusinessChannel.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully activate business channel';
        });

        builder.addCase(deActivateBusinessChannel.pending, (state, action) => {
            // state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(deActivateBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(
            deActivateBusinessChannel.fulfilled,
            (state, action) => {
                state.businessChannel = action.payload;
                state.loading = false;
                state.error = undefined;
                state.successMessage =
                    'Successfully deactivate business channel';
            }
        );

        builder.addCase(deleteBusinessChannel.pending, (state, action) => {
            // state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(deleteBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(deleteBusinessChannel.fulfilled, (state, action) => {
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully delete business channel';
        });
    },
});

export default businessChannelSlice.reducer;

export const { clearMessage } = businessChannelSlice.actions;
