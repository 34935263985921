/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */
import superagent from 'superagent';
import Config from '../../Config';
import { logout } from './AuthService';
import * as Utils from '../utils/Utils';
import * as logger from '../utils/Logger';

const MOSSBaseUrl = Config.mossApiBaseUrl;

export default {
    post,
    get,
    put,
    patch,
    del,
};

const portal = 'platform';

/**
 * Post (create or action) Api Service, supports authenticated and unauthenticated requests
 * @param {boolean} authenticated
 * @param {string} endpoint
 * @param {object} requestBody
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {string} [token]
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function post(
    authenticated,
    endpoint,
    requestBody,
    query = {},
    logoutOn401 = true,
    token = undefined,
    baseUrl = Config.apiBaseUrl
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token =
                    token ||
                    JSON.parse(
                        localStorage.getItem(`${Config.storageNameSpace}.token`)
                    );
                if (_token) {
                    superagent
                        .post(`${baseUrl}/${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('portal', portal)
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                if (baseUrl === Config.schedulerApiBaseUrl) {
                                    logger.error(res.body.message);
                                    return reject(res.body.message);
                                }
                                logger.error(res.body.error);
                                return reject(res.body.error);
                            }
                            if (err) {
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .post(`${baseUrl}/${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Get (Read) Api Service, supports authenticated and unauthenticated api requests
 * @param {boolean} authenticated
 * @param {string} endpoint
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {object} token
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function get(
    authenticated,
    endpoint,
    query = {},
    logoutOn401 = true,
    token = undefined,
    baseUrl = Config.apiBaseUrl
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token =
                    token ||
                    JSON.parse(
                        localStorage.getItem(`${Config.storageNameSpace}.token`)
                    );

                if (_token) {
                    superagent
                        .get(`${baseUrl}/${endpoint}`)
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('Cache-Control', 'no-store')
                        .set('portal', portal)
                        .query(generateQueryString(query))
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                if (baseUrl === Config.schedulerApiBaseUrl) {
                                    logger.error(res.body.message);
                                    return reject({
                                        message: res.body.message,
                                        status: res.statusCode,
                                    });
                                }
                                logger.error(res.body.error);
                                return reject(
                                    Utils.responseError(res.body.error)
                                );
                            }
                            if (err) {
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .get(`${baseUrl}/${endpoint}`)
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .set('Cache-Control', 'no-cache')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Update Api Service, expects all requests to be authenticated
 * @param {string} endpoint
 * @param {object} requestBody
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function put(
    authenticated,
    endpoint,
    requestBody,
    query = {},
    logoutOn401 = true,
    baseUrl = Config.apiBaseUrl
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token = JSON.parse(
                    localStorage.getItem(`${Config.storageNameSpace}.token`)
                );
                if (_token) {
                    superagent
                        .put(`${baseUrl}/${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('portal', portal)
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                // console.error(res.body.error);
                                // Raven.captureException(res.body.error);
                                logger.error(res.body.error);
                                return reject(res.body.error);
                            }
                            if (err) {
                                // console.error(err);
                                // Raven.captureException(err);
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .put(`${baseUrl}/${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            // console.error(res.body.error);
                            // Raven.captureException(res.body.error);
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            // console.error(err);
                            // Raven.captureException(err);
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Partial Update Api Service, expects all requests to be authenticated
 * @param {string} endpoint
 * @param {object} requestBody
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function patch(
    authenticated,
    endpoint,
    requestBody,
    query = {},
    logoutOn401 = true,
    baseUrl = Config.apiBaseUrl
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token = JSON.parse(
                    localStorage.getItem(`${Config.storageNameSpace}.token`)
                );
                if (_token) {
                    superagent
                        .patch(`${baseUrl}/${endpoint}`)
                        .send(requestBody)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .set('portal', portal)
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                // console.error(res.body.error);
                                // Raven.captureException(res.body.error);
                                logger.error(res.body.error);
                                return reject(res.body.error);
                            }
                            if (err) {
                                // console.error(err);
                                // Raven.captureException(err);
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .patch(`${baseUrl}/${endpoint}`)
                    .send(requestBody)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            // console.error(res.body.error);
                            // Raven.captureException(res.body.error);
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            // console.error(err);
                            // Raven.captureException(err);
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Delete Api Service, expects all requests to be authenticated
 * @param {string} endpoint
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function del(
    endpoint,
    query = {},
    logoutOn401 = true,
    baseUrl = Config.apiBaseUrl
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            const _token = JSON.parse(
                localStorage.getItem(`${Config.storageNameSpace}.token`)
            );

            if (_token) {
                superagent
                    .del(`${baseUrl}/${endpoint}`)
                    .set('Authorization', _token.id)
                    .set('portal', portal)
                    .query(generateQueryString(query))
                    .end((err, res) => {
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            if (baseUrl === Config.schedulerApiBaseUrl) {
                                logger.error(res.body.message);
                                return reject(res.body.message);
                            }
                            // console.error(res.body.error);
                            // Raven.captureException(res.body.error);
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            // console.error(err);
                            // Raven.captureException(err);
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            } else {
                logout();
                reject(Utils.unathenticatedErrorObject());
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Post a file to Api Service, supports authenticated and unauthenticated requests
 * @param {boolean} authenticated
 * @param {string} endpoint
 * @param {String|Blob|Buffer} file
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @param {string} baseUrl
 * @returns {Promise}
 */
export function upload(
    authenticated,
    endpoint,
    file,
    query = {},
    logoutOn401 = true,
    baseUrl = Config.apiBaseUrl
) {
    if (file.size > 10000000) {
        return Promise.reject(
            new Error(
                `File size ${(file.size / 1000000).toPrecision(
                    3
                )}MB too large, maximum 10MB`
            )
        );
    }
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token = JSON.parse(
                    localStorage.getItem(`${Config.storageNameSpace}.token`)
                );
                if (_token) {
                    superagent
                        .post(`${baseUrl}/${endpoint}`)
                        .attach(file.name, file)
                        .query(generateQueryString(query))
                        .set('Accept', 'application/json')
                        .set('Authorization', _token.id)
                        .set('portal', portal)
                        /* old portals code for reference
            .then((response) => {
                const cloudinaryError = _.get(response, 'data.result.error', null);
                if (cloudinaryError){
                    return deferred.reject(cloudinaryError);
                }
                return deferred.resolve(response.data.result);
            })
            .catch((error) => {
                const err = new Error(error.statusText);
                err.statusCode = error.status;
                return deferred.reject(err);
            });
            */
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                // console.error(res.body.error);
                                // Raven.captureException(res.body.error);
                                logger.error(res.body.error);
                                return reject(res.body.error);
                            }
                            if (err) {
                                // console.error(err);
                                // Raven.captureException(err);
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .post(`${baseUrl}/${endpoint}`)
                    .attach('file1', file)
                    .query(generateQueryString(query))
                    .set('Accept', 'application/json')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            // console.error(res.body.error);
                            // Raven.captureException(res.body.error);
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            // console.error(err);
                            // Raven.captureException(err);
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Get (Read) Api Service, supports authenticated and unauthenticated api requests
 * @param {boolean} authenticated
 * @param {string} endpoint
 * @param {object} [query={}]
 * @param {boolean} [logoutOn401=true]
 * @returns {Promise}
 */
export function MOSSGet(
    authenticated,
    endpoint,
    query = {},
    logoutOn401 = true
) {
    return new Promise((resolve, reject) => {
        if (Utils.supportsLocalStorage()) {
            if (authenticated) {
                const _token = JSON.parse(
                    localStorage.getItem(`${Config.storageNameSpace}.token`)
                );

                if (_token) {
                    superagent
                        .get(`${MOSSBaseUrl}/${endpoint}`)
                        .set('Accept', 'application/json')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', _token.id)
                        .query(generateQueryString(query))
                        .end((err, res) => {
                            if (err && err.status === 401 && logoutOn401) {
                                logout();
                            }
                            if (err && res) {
                                // console.error(res.body.error);
                                // Raven.captureException(res.body.error);
                                logger.error(res.body.error);
                                // return reject(res.body.error);
                                return reject(
                                    Utils.responseError(res.body.error)
                                );
                            }
                            if (err) {
                                // console.error(err);
                                // Raven.captureException(err);
                                logger.error(err);
                                return reject(err);
                            }
                            return resolve(res.body);
                        });
                } else {
                    logout();
                    reject(Utils.unathenticatedErrorObject());
                }
            } else {
                superagent
                    .get(`${MOSSBaseUrl}/${endpoint}`)
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .end((err, res) => {
                        // TODO: The following block seems to not be needed
                        // since we are not logged in if we are in this block of code
                        if (err && err.status === 401 && logoutOn401) {
                            logout();
                        }
                        if (err && res) {
                            // console.error(res.body.error);
                            // Raven.captureException(res.body.error);
                            logger.error(res.body.error);
                            return reject(res.body.error);
                        }
                        if (err) {
                            // console.error(err);
                            // Raven.captureException(err);
                            logger.error(err);
                            return reject(err);
                        }
                        return resolve(res.body);
                    });
            }
        } else {
            reject(
                new Error('Unhandled Exception, LocalStorage is Not Supported')
            );
        }
    });
}

/**
 * Stringifies query object and returns URI encoded string
 * @param {Object} query
 * @returns {String} query string
 */
export function generateQueryString(query) {
    const queryKeys = Object.keys(query);
    return queryKeys
        .map((key, idx) => {
            const value = query[key];
            if (value === undefined) {
                return '';
            }
            if (idx === 0) {
                return `${encodeURIComponent(key)}=${
                    typeof value === 'object'
                        ? encodeURIComponent(JSON.stringify(value))
                        : encodeURIComponent(value)
                }`;
            }
            return `&${encodeURIComponent(key)}=${
                typeof value === 'object'
                    ? encodeURIComponent(JSON.stringify(value))
                    : encodeURIComponent(value)
            }`;
        })
        .join('');
}
