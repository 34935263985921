/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const Alert = () => {
    /**
     * Global config for alert
     */
    const location = useLocation();
    useEffect(() => {
        Alert.pageErrorIds.forEach(id => {
            toast.dismiss(id);
        });
        Alert.pageErrorIds = [];
    }, [location.pathname]);
    return (
        <ToastContainer
            position='top-left'
            theme='colored'
            autoClose={6000}
            closeOnClick
            pauseOnHover
            pauseOnFocusLoss={false}
            draggable
        />
    );
};

Alert.pageErrorIds = [];

/**
 *
 * @param {string | JSX.Element} msg
 * @param {object} [options]
 * @param {int | boolean} [options.autoClose]
 * @param {string} [options.toastId]
 */
Alert.info = (msg, options) => {
    toast.info(msg, {
        icon: () => <i className='fa fa-info-circle  fa-2x' />,
        ...options,
    });
};

/**
 *
 * @param {string | JSX.Element} msg
 * @param {object} [options]
 * @param {int | boolean} [options.autoClose]
 * @param {string} [options.toastId]
 */
Alert.error = (msg, options) => {
    const errorId = toast.error(msg, {
        autoClose: false,
        icon: () => <i className='fa fa-exclamation-triangle  fa-2x' />,
        ...options,
    });
    Alert.pageErrorIds.push(errorId);
};

/**
 *
 * @param {string | JSX.Element} msg
 * @param {object} [options]
 * @param {int | boolean} [options.autoClose]
 * @param {string} [options.toastId]
 */
Alert.warning = (msg, options) => {
    toast.warning(msg, {
        icon: () => <i className='fa fa-exclamation-circle fa-2x' />,
        ...options,
    });
};

/**
 *
 * @param {string | JSX.Element} msg
 * @param {object} [options]
 * @param {int | boolean} [options.autoClose]
 * @param {string} [options.toastId]
 */
Alert.success = (msg, options) => {
    toast.success(msg, {
        autoClose: 2500,
        icon: () => <i className='fa fa-check-circle  fa-2x' />,
        ...options,
    });
};

/**
 * Close all alerts
 */
Alert.close = () => {
    toast.dismiss();
};

export default Alert;
