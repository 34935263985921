/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { State } from 'common/types/filters';
import * as FilterAction from 'common/actions/redux/FilterAction';
import { invoiceTypesProperties } from 'common/types/invoices';
import moment from 'moment-timezone';

const defaultSelectedInvoiceType = {
    id: invoiceTypesProperties.sales.property,
    display: invoiceTypesProperties.sales.display,
};

const getInitialOrder = (selectedInvoiceType: string) => {
    return selectedInvoiceType === invoiceTypesProperties.sales.property
        ? [{ property: 'invoiceCreated', sortBy: 'DESC' }]
        : [{ property: 'invoiceDate', sortBy: 'DESC' }];
};

const initialState: State & ExtendFilterState = {
    activeFilters: [],
    filterId: 101,
    advancedFilterId: 501,
    activePage: 1,
    selectedInvoiceType: defaultSelectedInvoiceType,
    orders: getInitialOrder(defaultSelectedInvoiceType.id),
    updated: moment().format('x'),
};

interface ExtendFilterState {
    selectedInvoiceType: { id: string; display: string };
}

const adminFinanceInvoicesListFilterSlice = createSlice({
    name: 'adminFinanceInvoicesListFilter',
    initialState,
    reducers: {
        ...FilterAction,
        setSelectedInvoiceType: (state, action) => ({
            ...initialState,
            orders: getInitialOrder(action.payload?.id),
            selectedInvoiceType: action.payload,
        }),
        initPageAndSort: (state, action) => ({
            ...state,
            activePage: 1,
            orders: getInitialOrder(state.selectedInvoiceType.id),
            ...action.payload,
        }),
        init: () => ({
            ...initialState,
            updated: moment().format('x'),
        }),
    },
});

export const actions = adminFinanceInvoicesListFilterSlice.actions;
export default adminFinanceInvoicesListFilterSlice.reducer;
