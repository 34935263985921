/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegisteredContractor } from 'common/types';

const initialState: { registration: RegisteredContractor } = {
    registration: {
        _id: '',
        signatureId: '',
        signatureStatus: 'NOT SENT',
        created: '',
        lastModified: '',
        uuid: '',
        status: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        trades: [],
        notes: [],
        companyInfo: {
            name: '',
            website: '',
            companyAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            },
            billingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            },
            useCompanyAddressAsBilling: true,
        },
        teamInfo: {
            systemInstalls: '',
            serviceCalls: '',
            masterTechs: '',
            employees: '',
            singleFamilyServiceProvider: false,
            multiDwellingServiceProvider: false,
            commercialServiceProvider: false,
        },
        serviceArea: '',
        documents: {
            epaCertification: [],
            w9: [],
            tradeLicenses: [],
            certificateOfLiability: [],
            codeOfConduct: [],
            companyMSA: [],
            masterServiceAgreement: [],
        },
    },
};

const contractorRegistrationSlice = createSlice({
    name: 'contractorRegistration',
    initialState,
    reducers: {
        setRegistration(state, action: PayloadAction<RegisteredContractor>) {
            state.registration = action.payload;
        },
    },
});

export const { setRegistration } = contractorRegistrationSlice.actions;

export default contractorRegistrationSlice.reducer;
