// Helpers
import {
    FormDefinition,
    FormDefinitionType,
    FormResponse,
    Identity,
} from 'common/types/requirements';
import { UISchemaElement } from '@jsonforms/core';

const areEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
};
const getIdentity = (item: Identity): Identity => {
    return {
        pk: item.pk,
        sk: item.sk,
    };
};
export const idsAreEqual = (a: Identity, b: Identity) => {
    return areEqual(getIdentity(a), getIdentity(b));
};
export const buildUiSchema = (
    formResponse: FormResponse,
    formDefinition: FormDefinition
) => {
    if (!formDefinition.ui) {
        throw formDefError(
            'The form definition is missing the ui definition',
            formDefinition
        );
    }
    const defs = formResponse.uiSchemas;
    const elements: UISchemaElement[] = [];
    let required: string[] = [];

    const maybeAddRequired = (requiredOrUndefined?: string[]) => {
        if (requiredOrUndefined && requiredOrUndefined.length > 0) {
            required = required.concat(requiredOrUndefined);
        }
    };

    const { ui } = formDefinition;
    ui.forEach(defOrRef => {
        if (defOrRef.type === FormDefinitionType.uiSchema) {
            elements.push(defOrRef.uiSchema);
            maybeAddRequired(defOrRef.required);
        } else {
            const found = defs.find(uiSchema => {
                const { pk, sk } = uiSchema;
                return areEqual(defOrRef.ref, { pk, sk });
            });
            if (!found) {
                throw formDefError('UiSchema cannot be found', defOrRef);
            }
            maybeAddRequired(found.required);
            elements.push(found.uiSchema);
        }
    });
    return {
        uiSchema: {
            type: 'VerticalLayout',
            elements,
        },
        required,
    };
};

export const buildSchema = (
    formResponse: FormResponse,
    formDefinition: FormDefinition
) => {
    if (!formDefinition?.schema) {
        throw formDefError(
            'The form definition is missing the schema definition',
            formDefinition
        );
    }
    const defs = formResponse.schemas;
    const found = defs.find(schema => {
        const { pk, sk } = schema;
        return areEqual(formDefinition.schema, { pk, sk });
    });
    if (!found) {
        throw formDefError('Schema cannot be found', formDefinition);
    }
    return found.schema;
};
const formDefError = (msg: string, formDefinition: any) => {
    const def = JSON.stringify(formDefinition, undefined, 2);
    const err = new Error(`${msg}: \n ${def}`);
    err.name = 'Form Definition Error';
    return err;
};
