/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

export enum INVOICE_PROPERTY {
    SALES_INVOICE = 'salesInvoice',
    PURCHASE_INVOICE = 'purchaseInvoice',
}

export interface InvoiceType {
    property:
        | INVOICE_PROPERTY.SALES_INVOICE
        | INVOICE_PROPERTY.PURCHASE_INVOICE;
    name: string;
    display: string;
}

export interface InvoiceTypeProperty {
    [key: string]: InvoiceType;
}

export const invoiceTypesProperties: InvoiceTypeProperty = {
    sales: {
        property: INVOICE_PROPERTY.SALES_INVOICE,
        name: 'SALES INVOICE',
        display: 'Sales Invoice',
    },
    purchase: {
        property: INVOICE_PROPERTY.PURCHASE_INVOICE,
        name: 'PURCHASE INVOICE',
        display: 'Purchase Invoice',
    },
};

export interface SelectedInvoice {
    display: string;
    id: string;
}

export interface Term {
    id: string;
    display: string;
    days: number;
}
