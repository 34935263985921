/**
 * @license
 * @copyright Copyright Motili Inc., 2022 All Rights Reserved
 */
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
    isFulfilled,
    isPending,
    isRejected,
    miniSerializeError,
    SerializedError,
} from '@reduxjs/toolkit';
import find from 'lodash/find';

import requirementsService, {
    RequirementsDto,
    RequirementsResponse,
} from 'common/services/RequirementsService';
import { FormResponse, RenderableForm } from 'common/types/requirements';
import { RootState } from 'common/stores/redux/store';
import {
    buildSchema,
    buildUiSchema,
} from 'common/utils/requirements/requirementsBuilderUtils';
import { RequestStatus } from 'common/types/requestStatus';

interface RequirementsState extends EntityState<RequirementsValue> {
    status: RequestStatus;
    error: SerializedError | undefined;
    definitions: RenderableForm[];
    selectedEquipment?: string;
    selectedDefinition?: RenderableForm;
    selectedWorkOrder?: number;
}

interface RequirementsValue {
    data: RequirementsDto;
    form: RenderableForm;
}

const requirementsAdapter = createEntityAdapter<RequirementsValue>({
    selectId: (req: RequirementsValue) => {
        return req.data.assetId;
    },
});

const initialState: RequirementsState = requirementsAdapter.getInitialState({
    status: RequestStatus.idle,
    error: undefined,
    definitions: [],
});

export const fetchRequirementsSpecs = createAsyncThunk(
    'requirements/fetchRequirementsSpecs',
    async () => {
        const response = await requirementsService.fetchAllSchemaDefs();
        return unpackForms(response as FormResponse);
    }
);

export const saveRequirements = createAsyncThunk<
    RequirementsValue,
    RequirementsDto
>('requirements/saveRequirements', async (requirements: RequirementsDto) => {
    const response: RequirementsResponse =
        await requirementsService.saveRequirements(requirements);
    return {
        data: response.data[0],
        form: unpackForms(response.dependencies)[0],
    };
});

export const fetchRequirements = createAsyncThunk<RequirementsValue[], number>(
    'requirements/fetchRequirements',
    async (workOrderId: number) => {
        const response =
            await requirementsService.fetchRequirementsForWorkOrder(
                workOrderId
            );
        const forms = unpackForms(response.dependencies);
        const mapped = response.data.map(data => {
            const form = findSchemaByVersion(forms, data.version);
            return {
                data,
                form,
            };
        });
        return (mapped || []) as RequirementsValue[];
    }
);

// function findSchema(defs: RenderableForm[],  equipmentType: string) {
//   if(!equipmentType) throw new Error("Equipment type must be provided");
//   return defs.find((def)=> {
//     if(!def.equipmentType) {
//       return false;
//     }
//       return def.equipmentType.toUpperCase() === equipmentType.toUpperCase();
//   });
// }

export function findSchema(
    defs: RenderableForm[],
    equipmentType: string,
    clientId: number,
    version?: string
) {
    if (!equipmentType) return null;
    if (version) {
        return find(defs, def => def.sk === version);
    }
    const clientSpecificSchema = find(defs, def => {
        if (!def.equipmentType) {
            return null;
        }
        return (
            def.clientId === clientId &&
            def.equipmentType.toUpperCase() === equipmentType.toUpperCase()
        );
    });
    if (clientSpecificSchema) return clientSpecificSchema;
    return defs.find(def => {
        if (!def.equipmentType) {
            return null;
        }

        return (
            !def.clientId &&
            def.equipmentType.toUpperCase() === equipmentType.toUpperCase()
        );
    });
}

function findSchemaByVersion(defs: RenderableForm[], version: string) {
    if (!version) throw new Error('Version must be provided');
    return defs.find(def => {
        if (!def.sk) {
            return false;
        }
        return def.sk === version;
    });
}

const requirementsSlice = createSlice({
    name: 'requirements',
    initialState,
    reducers: {
        // equipmentSelected(state, action) {
        //     const equipmentType = action.payload;
        //     state.selectedEquipment = equipmentType;
        //     state.selectedDefinition = findSchema(
        //         state.definitions,
        //         equipmentType
        //     );
        // },
    },
    extraReducers(builder) {
        builder
            .addCase(saveRequirements.fulfilled, requirementsAdapter.upsertOne)
            .addCase(
                fetchRequirements.fulfilled,
                requirementsAdapter.upsertMany
            )
            .addCase(fetchRequirementsSpecs.fulfilled, (state, action) => {
                state.definitions = action.payload;
            })
            .addMatcher(isPending, (state, action) => {
                state.status = RequestStatus.loading;
            })
            .addMatcher(isFulfilled, (state, action) => {
                state.status = RequestStatus.succeeded;
            })
            .addMatcher(isRejected, (state, action) => {
                state.status = RequestStatus.failed;
                const error = new Error(
                    `There was a problem completing a request to the requirements engine: ${action.error.message}`
                );
                error.cause = action.error as Error;
                state.error = miniSerializeError(error);
            });
    },
});

// export const {} = requirementsSlice.actions;
export default requirementsSlice.reducer;

export const unpackForms = (formResponse: FormResponse): any => {
    return formResponse.forms.map(form => {
        const {
            pk,
            sk,
            name,
            description,
            equipmentType,
            status,
            version,
            createdBy,
            createdAt,
        } = form;
        const { uiSchema, required } = buildUiSchema(formResponse, form);
        const schema = buildSchema(formResponse, form);
        return {
            pk,
            sk,
            name,
            description,
            equipmentType,
            status,
            version,
            schema,
            uiSchema,
            required,
            createdBy,
            createdAt,
        };
    });
};

export const SelectAllForms = (state: RootState) => {
    return state.requirements.definitions;
};

export const GetSelectedDefinition = (state: RootState) => {
    return state.requirements.selectedDefinition;
};

export const SelectStatus = (state: RootState) => {
    return state.requirements.status;
};

export const SelectError = (state: RootState) => {
    return state.requirements.error;
};

export const SelectRequirementsById =
    requirementsAdapter.getSelectors().selectById;

export const SelectSubmittedRequirementsCount = (state: RootState) => {
    return state.requirements.ids.length;
};

export const SelectSubmittedReqs = (state: RootState) => {
    return requirementsAdapter.getSelectors().selectAll(state.requirements);
};

export const SelectDefinition = (
    state: RootState,
    equipmentType: string,
    clientId: number,
    version: string
) => {
    return findSchema(
        state.requirements.definitions,
        equipmentType,
        clientId,
        version
    );
};
