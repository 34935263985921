/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import './style/sass/layout.scss'; // eslint-disable-line no-unused-vars

/**
 * Content Wrapper
 */
export default function Wrapper(props) {
    return (
        <div className='layout'>
            <div className='wrapper'>{props.children}</div>
        </div>
    );
}
