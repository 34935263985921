/**
 * Copyright Motili Inc., 2017 All Rights Reserved
 */

import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ServiceWorkerContext } from 'useServiceWorker';
import { UserTokenContext } from 'useUserToken';
import Config from 'Config';
import Alert from 'common/components/Alert';
import { updatePassword as AuthServiceUpdatePassword } from '../../services/AuthService';
import AuthStore from '../../stores/AuthStore';
import ChangePassword from './ChangePassword';
/**
 * Change Password
 *
 */

export default function ChangePasswordContainer() {
    const serviceWorker = useContext(ServiceWorkerContext);
    const userToken = useContext(UserTokenContext);
    return (
        <ChangePasswordContainerComponent
            serviceWorker={serviceWorker}
            userToken={userToken}
        />
    );
}

const ChangePasswordContainerComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [passwordSuccessfullyReset, setPasswordSuccessfullyReset] =
        useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(AuthStore.getState().loading);
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');

    useEffect(() => {
        const unlisten = AuthStore.listen(onChange);
        return () => unlisten();
    }, []);

    const onChange = state => {
        setLoading(state.loading);
    };

    return (
        <div>
            <ChangePassword
                loading={loading}
                error={error}
                password={password}
                confirmationPassword={confirmationPassword}
                passwordSuccessfullyReset={passwordSuccessfullyReset}
                handlePasswordChange={password => setPassword(password)}
                handleConfirmationPasswordChange={password =>
                    setConfirmationPassword(password)
                }
                handleSubmitPassword={e => handleSubmitPassword(e)}
                handleGoToAccountLogin={() => navigate('/login')}
            />
        </div>
    );

    function handleSubmitPassword(e) {
        e.preventDefault();

        if (!password && !confirmationPassword) {
            return setError('No password specified.');
        }

        if (password !== confirmationPassword) {
            return setError('Passwords do not match.');
        }
        setError(null);

        const token = JSON.parse(
            localStorage.getItem(`${Config.storageNameSpace}.token`)
        );

        const resetPasswordData = {
            email: token.user.email,
            password,
            confirmationPassword,
            token: accessToken,
        };

        return AuthServiceUpdatePassword(resetPasswordData)
            .then(async response => {
                setPasswordSuccessfullyReset(true);
                return response;
            })
            .catch(errObject => {
                setError(errObject.message);
                if (errObject.message === 'Invalid token') {
                    Alert.error(
                        'There was an error processing the request, please contact support@motili.com if you need assistance logging into your account.'
                    );
                }
            });
    }
};
