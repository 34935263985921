/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { post } from './common/services/ApiService';
import Config from './Config';

async function addSubscription(token: string): Promise<object> {
    return post(true, 'FcmSubscriptions/addSubscription', {
        type: 'token',
        value: token,
        system: 'web',
    });
}

export const initFirebaseMessaging = async (
    registration?: ServiceWorkerRegistration
) => {
    try {
        const app = initializeApp(Config.firebase.app);
        const messaging = getMessaging(app);

        // wait until service worker is ready
        await navigator.serviceWorker.ready;

        const currentToken = await getToken(messaging, {
            vapidKey: Config.firebase.messaging.vapidKey,
            serviceWorkerRegistration: registration,
        });

        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // Register on message
            onMessage(messaging, payload => {
                let messageType = '';
                let remindersMessage = null;
                if (payload.data?.comment) {
                    const message = JSON.parse(payload.data?.comment);
                    if (message.reminder) {
                        remindersMessage = JSON.parse(message.reminder);
                        messageType = remindersMessage.type;
                    }
                }

                if (messageType === 'REMINDERS_NOTIFICATION') {
                    window.dispatchEvent(
                        new CustomEvent('REMINDERS_NOTIFICATION', {
                            detail: remindersMessage,
                        })
                    );
                } else {
                    window.dispatchEvent(
                        new CustomEvent('DASHBOARD_COMMENT', {
                            detail: payload,
                        })
                    );
                }
            });
            await addSubscription(currentToken);
        } else {
            // Show permission request UI
            console.log(
                'No registration token available. Request permission to generate one.'
            );
        }
    } catch (err) {
        console.log('An error occurred while init firebase messaging.', err);
    }
};
