/**
 * Copyright Motili Inc., 2023 All Rights Reserved
 */

import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useServiceWorker } from 'useServiceWorker';
import ReactGA from 'react-ga';

export const history = {
    push: undefined,
    location: undefined,
};

const GlobalHistory = () => {
    const location = useLocation();
    history.push = useNavigate();
    const { setPathname } = useServiceWorker();

    useEffect(() => {
        history.location = location;
        setPathname(location.pathname);
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }, [location, setPathname]);

    return null;
};

export default GlobalHistory;
