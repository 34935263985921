/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import alt from '../../alt';

class AuthActions {
    constructor() {
        this.generateActions(
            'fetchedToken',
            'resetAuthentication',
            'updateAccount',
            'acceptAcknowledgement'
        );
    }
}

export default alt.createActions(AuthActions);
