/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

// TODO: This component has not been updated to follow the 'common' component paradigm

/* eslint-disable no-useless-constructor */

import { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

/**
 * FormEmailInput
 *
 * Standard Motili Password Input for use within a form
 *
 */
export default class FormPasswordInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const _label = this.props.noLabel ? null : (
            <ControlLabel style={this.props.labelStyle || {}}>
                {this.props.label || 'Password'}
            </ControlLabel>
        );
        return (
            <FormGroup
                controlId={this.props.controlId || 'passwordInput'}
                bsSize={this.props.bsSize || 'small'}
            >
                {_label}
                <FormControl
                    type='password'
                    value={this.props.value}
                    placeholder={this.props.placeholder || 'Password'}
                    onChange={this.props.onChange}
                />
            </FormGroup>
        );
    }
}
