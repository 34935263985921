import { createSlice } from '@reduxjs/toolkit';

export const dataGridStateSlice = createSlice({
    name: 'dataGridState',
    initialState: {},
    reducers: {
        resetGridState: () => {
            return {};
        },
        initializeGridState: (state, action) => {
            const { gridName, initialState } = action.payload;
            if (!state[gridName]) {
                state[gridName] = initialState;
            }
        },
        setGridState: (state, action) => {
            const { gridName, gridState } = action.payload;
            state[gridName] = gridState;
        },
        deleteGridState: (state, action) => {
            const { gridName } = action.payload;
            delete state[gridName];
        },
        setColumnVisibility: (state, action) => {
            const { gridName, columnVisibility } = action.payload;
            if (state[gridName]) {
                state[gridName].columnVisibility = columnVisibility;
            } else {
                state[gridName] = { columnVisibility };
            }
        },
        setPinnedColumns: (state, action) => {
            const { gridName, pinnedColumns } = action.payload;
            if (state[gridName]) {
                state[gridName].pinnedColumns = pinnedColumns;
            } else {
                state[gridName] = { pinnedColumns };
            }
        },
        setSortModel: (state, action) => {
            const { gridName, sortModel } = action.payload;
            if (state[gridName]) {
                state[gridName].sortModel = sortModel;
            } else {
                state[gridName] = { sortModel };
            }
        },
        setDensity: (state, action) => {
            const { gridName, density } = action.payload;
            if (state[gridName]) {
                state[gridName].density = density;
            } else {
                state[gridName] = { density };
            }
        },
        setColumnOrdering: (state, action) => {
            const { gridName, columnOrdering } = action.payload;
            if (state[gridName]) {
                state[gridName].columnOrdering = columnOrdering;
            } else {
                state[gridName] = { columnOrdering };
            }
        },
        setColumnDimensions: (state, action) => {
            const { gridName, columnDimensions } = action.payload;
            if (state[gridName]) {
                state[gridName].columnDimensions = columnDimensions;
            } else {
                state[gridName] = { columnDimensions };
            }
        },
        setActiveFilters: (state, action) => {
            const { gridName, activeFilters } = action.payload;
            if (state[gridName]) {
                state[gridName].activeFilters = [...activeFilters];
            } else {
                state[gridName] = { activeFilters };
            }
        },
        setRowGroupingModel: (state, action) => {
            const { gridName, rowGroupingModel } = action.payload;
            if (state[gridName]) {
                state[gridName].rowGroupingModel = rowGroupingModel;
            } else {
                state[gridName] = { rowGroupingModel };
            }
        },
        setGridViewActiveId: (state, action) => {
            const { gridName, activeId } = action.payload;
            if (state[gridName]) {
                state[gridName].activeId = activeId;
            } else {
                state[gridName] = { activeId };
            }
        },
    },
});

export const {
    resetGridState,
    initializeGridState,
    setGridState,
    deleteGridState,
    setColumnVisibility,
    setPinnedColumns,
    setSortModel,
    setColumnDimensions,
    setColumnOrdering,
    setDensity,
    setActiveFilters,
    setRowGroupingModel,
    setGridViewActiveId,
} = dataGridStateSlice.actions;

export default dataGridStateSlice.reducer;
