import ca from './countries/ca';

/**
 *
 * @param {string} term
 * @param {object} terminologies
 * @param {object} [options]
 * @param {boolean} [options.upperCase]
 * @param {boolean} [options.plural]
 * @param {boolean} [options.abbreviation]
 * @returns {string}
 */
const process = (term, terminologies, options) => {
    let termObject;
    if (terminologies) {
        termObject = terminologies[term];
    }
    let formattedTerm;
    if (termObject) {
        if (options?.abbreviation) {
            if (termObject.abbreviation) {
                formattedTerm = termObject.abbreviation;
            } else {
                formattedTerm = term;
            }
        } else if (options?.plural) {
            if (termObject.plural) {
                formattedTerm = termObject.plural;
            } else {
                formattedTerm = term;
            }
        } else if (termObject.singular) {
            formattedTerm = termObject.singular;
        } else {
            formattedTerm = term;
        }
    } else {
        formattedTerm = term;
    }

    if (options?.upperCase) {
        return formattedTerm.toUpperCase();
    }
    return formattedTerm;
};
/**
 *
 * @param {string} term
 * @param {string} country
 * @param {object} [options]
 * @param {boolean} [options.upperCase]
 * @param {boolean} [options.plural]
 * @param {boolean} [options.abbreviation]
 */
export const getTerminology = (term, country, options) => {
    if (!term) {
        return term;
    }
    let terminologies;
    switch (country) {
        case 'CA':
            terminologies = ca;
            break;
    }
    return process(term, terminologies, options);
};
