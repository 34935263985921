/**
 * Copyright Motili Inc., 2017 All Rights Reserved
 */

import { Button, Image } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import FormPasswordInput from '../../components/form/FormPasswordInput';
import Config from '../../../Config';

import background from '../../../images/splash2.png';
import logo from '../../../images/Motili-Logo-White.svg';

export default function ChangePassword(props) {
    return (
        <div style={styles.background}>
            <div style={styles.formContainer}>
                <div className='login-form' style={{ flex: 0.45 }}>
                    <div className='login-form-top'>
                        <Image
                            src={logo}
                            responsive
                            className='login-form-logo'
                            style={{ height: 150 }}
                        />
                    </div>
                    <div
                        className='login-form-bottom'
                        style={{ maxHeight: 'none' }}
                    >
                        {!props.passwordSuccessfullyReset ? (
                            <ChangePasswordForm {...props} />
                        ) : (
                            <PasswordSuccessfullyReset />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
ChangePassword.propTypes = {
    passwordSuccessfullyReset: PropTypes.bool,
};

ChangePassword.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    password: PropTypes.string.isRequired,
    confirmationPassword: PropTypes.string.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    handleConfirmationPasswordChange: PropTypes.func.isRequired,
    handleSubmitPassword: PropTypes.func.isRequired,
    handleGoToAccountLogin: PropTypes.func.isRequired,
    passwordSuccessfullyReset: PropTypes.bool.isRequired,
};

function ChangePasswordForm({
    loading,
    error,
    password,
    confirmationPassword,
    handlePasswordChange,
    handleConfirmationPasswordChange,
    handleSubmitPassword,
    handleGoToAccountLogin,
}) {
    function isFormValid() {
        return (
            !loading &&
            password &&
            confirmationPassword &&
            password === confirmationPassword
        );
    }

    return (
        <div>
            <TransitionGroup>
                {!!error && (
                    <CSSTransition timeout={250} classNames='example'>
                        <div className='reset-password-error-text '>
                            {_renderError(error)}
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
            <form
                key='resetPassword'
                onSubmit={e => handleSubmitPassword(e)}
                style={{ marginTop: 20 }}
            >
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        placeholder='New Password'
                        value={password}
                        bsSize='large'
                        onChange={e => {
                            handlePasswordChange(e.target.value);
                        }}
                    />
                </div>
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        placeholder='Repeat Password'
                        value={confirmationPassword}
                        bsSize='large'
                        onChange={e => {
                            handleConfirmationPasswordChange(e.target.value);
                        }}
                    />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <Button
                        bsStyle='primary'
                        bsSize='large'
                        type='submit'
                        block
                        disabled={!isFormValid()}
                    >
                        {loading ? 'Updating ...' : 'Update'}
                    </Button>
                </div>

                <div
                    style={styles.textLink}
                    onClick={() => handleGoToAccountLogin()}
                >
                    Go to account login
                </div>
            </form>
        </div>
    );
}
ChangePasswordForm.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    password: PropTypes.string,
    confirmationPassword: PropTypes.string,
    handlePasswordChange: PropTypes.func,
    handleConfirmationPasswordChange: PropTypes.func,
    handleSubmitPassword: PropTypes.func,
    handleGoToAccountLogin: PropTypes.func,
};

ChangePasswordForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    password: PropTypes.string.isRequired,
    confirmationPassword: PropTypes.string.isRequired,
    handlePasswordChange: PropTypes.func.isRequired,
    handleConfirmationPasswordChange: PropTypes.func.isRequired,
    handleSubmitPassword: PropTypes.func.isRequired,
    handleGoToAccountLogin: PropTypes.func.isRequired,
};

function PasswordSuccessfullyReset() {
    return (
        <div style={{ textAlign: 'center', fontSize: 20 }}>
            <div>Success! Your password has been reset.</div>
            <div style={{ marginTop: 20 }}>
                <a href='#/login'>Click to return to the login screen</a>
            </div>
        </div>
    );
}

function _renderError(error) {
    if (error === 'Invalid token') {
        return `${error} - Note: reset links are only valid for 24 hours, Please try reset link again`;
    }

    return error;
}

const styles = {
    background: {
        height: '100vh',
        minWidth: 768,
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
    },
    formContainer: {
        flexDirection: 'row',
        display: 'flex',
        flex: 0.75,
        justifyContent: 'space-around',
    },
    textLink: {
        cursor: 'pointer',
        color: 'white',
        fontSize: 14,
    },
    warningMessage: {
        color: Config.color.red,
        marginBottom: -15,
    },
};
