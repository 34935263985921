/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */
import { useEffect } from 'react';
import { useServiceWorker } from '../../../useServiceWorker';
import { initFirebaseMessaging } from '../../../firebase-messaging';

interface ServiceWorkerProvider {
    registration: ServiceWorkerRegistration;
}

export const MessagingRegister = () => {
    const { registration } = useServiceWorker() as ServiceWorkerProvider;
    useEffect(() => {
        if (registration) {
            initFirebaseMessaging(registration).catch(console.error);
        }
    }, [registration]);
    return null;
};
