/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */
/* eslint-disable */
/* global localStorage */

import Raven from 'raven-js';
import assign from 'lodash/assign';
import find from 'lodash/find';
import intersection from 'lodash/intersection';

import alt from '../../alt';
import Config from '../../Config';
import * as ApiService from './ApiService';
import * as ClientService from './ClientService';
import * as ContractorService from './ContractorService';
import {
    update as updateAccount,
    patch as patchAccount,
    findById as getAuthenticatedAccount,
    createPreference,
    createAcknowledgement,
} from './AccountService';
import moment from 'moment';
import { getActiveContractorMSA } from './ContractorService';

/**
 * login
 * @param {object} credentials
 * @returns {Promise}
 */
export async function login(credentials) {
    const _requestBody = assign({}, credentials, {
        portal: 'platform',
        realm: 'motili',
    });
    try {
        const response = await ApiService.post(
            false,
            'accounts/login',
            _requestBody,
            undefined,
            false
        );

        await _handleLoginResponse(response);
        if (!response.user.forcePasswordChange) {
            await _handleContractorLoginResponse();
        }
        return {
            token: JSON.parse(
                localStorage.getItem(`${Config.storageNameSpace}.token`)
            ),
            changePassword: response.user.forcePasswordChange,
        };
    } catch (errObject) {
        throw errObject;
    }
}

/**
 * Update local storage based on logged in users role
 * @param {object} response
 */
async function _handleLoginResponse(response) {
    clearCache();
    localStorage.setItem(
        `${Config.storageNameSpace}.token`,
        JSON.stringify({
            ...response,
            lastCheckFromBackend: moment().valueOf(),
        })
    );
    Raven.setUserContext({
        id: response.user.id,
        email: response.user.email,
    });
    if (isClientAccount()) {
        const clientId = getClientId();
        if (clientId) {
            Raven.setExtraContext({
                userContext: 'cli',
            });
            const client = await ClientService.findById(clientId, {
                filter: { include: ['address'] },
            });
            return localStorage.setItem(
                `${Config.storageNameSpace}.client`,
                JSON.stringify(client)
            );
        }
    } else if (isContractorAccount()) {
        const contractorId = getContractorId();
        if (contractorId) {
            Raven.setExtraContext({
                userContext: 'con',
            });
            const contractor = await ContractorService.findById(contractorId, {
                filter: { include: ['address', 'tradeTypes'] },
            });
            if (contractor.directSupply) {
                contractor.directSupplyClients =
                    await ContractorService.getDirectSupplyClients(
                        contractorId
                    );
            }

            // TODO JRo can add logic here for grabbing feature flags
            return localStorage.setItem(
                `${Config.storageNameSpace}.contractor`,
                JSON.stringify(contractor)
            );
        }
    } else {
        Raven.setExtraContext({
            userContext: 'ops',
        });
    }
    return true;
}

/**
 * Update local storage if user is a contractor
 */
async function _handleContractorLoginResponse() {
    if (isContractorAccount()) {
        const contractorMSA = await getActiveContractorMSA();
        const account = getAccount();
        const acknowledgment = find(account.acknowledgments, {
            type: contractorMSA.type,
            version: contractorMSA.version,
            acknowledged: true,
        });
        localStorage.setItem(
            `${Config.storageNameSpace}.contractorMSA`,
            JSON.stringify(
                assign(
                    {
                        type: contractorMSA.type,
                        version: contractorMSA.version,
                        acknowledged: false,
                    },
                    {
                        acknowledged:
                            acknowledgment && acknowledgment.acknowledged,
                    }
                )
            )
        );
    }
    return true;
}

/**
 *
 * @param {*} email
 */
export async function forgotPassword({ email }) {
    let response;
    const _requestBody = {
        email: email,
        realm: 'motili',
    };
    try {
        response = await ApiService.post(
            false,
            'accounts/forgotPassword',
            _requestBody
        );
    } catch (errObject) {
        throw errObject;
    }
    return response;
}

/**
 *
 * @param {*} resetPasswordData
 */
export async function resetPassword(resetPasswordData) {
    const _requestBody = assign(
        {},
        {
            realm: 'motili',
            portal: 'platform',
            password: resetPasswordData.password,
            confirmationPassword: resetPasswordData.confirmationPassword,
            token: resetPasswordData.token,
        }
    );
    return await ApiService.post(
        false,
        'accounts/updatePasswordWithToken',
        _requestBody
    ).catch(errObject => {
        throw errObject;
    });
}

/**
 *
 * @param {*} updatePasswordData
 */
export async function updatePassword(updatePasswordData) {
    const _requestBody = assign(
        {},
        {
            realm: 'motili',
            portal: 'platform',
            password: updatePasswordData.password,
            confirmationPassword: updatePasswordData.confirmationPassword,
            email: updatePasswordData.email,
        }
    );
    return await ApiService.post(
        false,
        'accounts/updatePasswordWithEmail',
        _requestBody
    ).catch(errObject => {
        throw errObject;
    });
}

/**
 * Logout
 */
export function logout() {
    if (!isLoggedIn()) {
        document.dispatchEvent(new CustomEvent('onParentLogout'));
    }
    clearCache();
    // Reset all stores to initial state
    alt.recycle();
    Raven.setUserContext();
}

/**
 * Check to see if a session exists
 * @returns {boolean}
 */
export function isLoggedIn() {
    return !!localStorage.getItem(`${Config.storageNameSpace}.token`);
}

/**
 * Get the currently logged in account token
 * @returns {object}
 */
export function getToken() {
    return JSON.parse(localStorage.getItem(`${Config.storageNameSpace}.token`));
}

/**
 * Get the currently logged in account
 */
export function getAccount() {
    return JSON.parse(localStorage.getItem(`${Config.storageNameSpace}.token`))
        .user;
}

/**
 * Get the currently logged in account's roles
 * @return {Array}
 */
export function getAccountRoles() {
    if (!localStorage.getItem(`${Config.storageNameSpace}.token`)) return [];
    const _account = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    ).user;
    const _roles = [];
    const cachedRoles = _account.roles || _account.motiliRoles; // temporary fix for people who have motiliRoles cached in local storage
    for (let i = 0; i < cachedRoles.length; i += 1) {
        _roles.push(cachedRoles[i].name);
    }
    return _roles;
}

/**
 * Returns true if the account is a motili account
 * @returns {boolean}
 */
export function isMotiliAccount() {
    const roles = [
        'motili_owner',
        'motili_admin',
        'motili_manager',
        'motili_user',
    ];
    return hasRole(roles);
}

/**
 * Returns true if the account is a motili manager or above
 * @returns {boolean}
 */
export function isMotiliManager() {
    const roles = ['motili_owner', 'motili_admin', 'motili_manager'];
    return hasRole(roles);
}

/**
 * Returns true if the account is a motili admin account
 * @returns {boolean}
 */
export function isMotiliAdmin() {
    const roles = ['motili_owner', 'motili_admin'];
    return hasRole(roles);
}

/**
 * Returns true if the account is a motili admin account
 * @returns {boolean}
 */
export function isMotiliOwner() {
    const roles = ['motili_owner'];
    return hasRole(roles);
}

/**
 * Returns true if the account is a motili account with finance department
 * @returns {boolean}
 */
export function isMotiliFinanceDept() {
    if (!isMotiliAccount()) return false;
    const departments = getAccount().department || [];
    return !!find(departments, d => d === 'Finance');
}

/**
 * Returns true if the account is a motili account with project management department
 * @returns {boolean}
 */
export function isMotiliProjectManager() {
    if (!isMotiliAccount()) return false;
    const departments = getAccount().department || [];
    return !!find(departments, d => d === 'Project Management');
}

/**
 * Returns true if the account is a motili account with contractor relations department
 * @returns {boolean}
 */
export function isMotiliContractorRelations() {
    if (!isMotiliAccount()) return false;
    const departments = getAccount().department || [];
    return !!find(departments, d => d === 'Contractor Relations');
}
/**
 * Returns true if the account is a motili account with engineering department
 * @returns {boolean}
 */
export function isMotiliEngineeringDept() {
    if (!isMotiliAccount()) return false;
    const departments = getAccount().department || [];
    return !!find(departments, d => d === 'Engineering');
}

/**
 * Returns true if the account is a client account
 * @returns {boolean}
 */
export function isClientAccount() {
    const roles = [
        'client_owner',
        'client_admin',
        'client_manager',
        'client_user',
    ];
    return hasRole(roles);
}

/**
 * Returns true if client only has read only access
 * @returns {boolean}
 */
export function isClientReadOnly() {
    const roles = ['client_user'];
    return hasRole(roles);
}

/**
 * Returns true if the account is a client account
 * @returns {boolean}
 */
export function isContractorAccount() {
    const roles = [
        'contractor_owner',
        'contractor_admin',
        'contractor_manager',
        'contractor_user',
    ];
    return hasRole(roles);
}

/**
 * Returns true if the account is a contractor admin account
 * @returns {boolean}
 */
export function isContractorAdmin() {
    const roles = ['contractor_owner', 'contractor_admin'];
    return hasRole(roles);
}

/**
 * hasRole - returns true if the account has the specified role(s)
 * @param {(string|string[])} role
 * @returns {boolean}
 */
export function hasRole(role) {
    if (Array.isArray(role)) {
        return intersection(role, getAccountRoles()).length > 0;
    }
    const idx = getAccountRoles().indexOf(role);
    return idx !== -1;
}

/**
 * Get the full name, formatted tied to the account
 * @returns {string}
 */
export function getAccountFullName() {
    const _account = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    ).user;
    return _account && _account.firstName && _account.lastName
        ? `${_account.firstName} ${_account.lastName}`
        : 'Anonymous';
}

/**
 * Get the account's user name
 * @return {*|String}
 */
export function getAccountUserName() {
    return JSON.parse(localStorage.getItem(`${Config.storageNameSpace}.token`))
        .user.username;
}

/**
 * Get account's id
 * @return {number} id
 */
export function getAccountId() {
    return JSON.parse(localStorage.getItem(`${Config.storageNameSpace}.token`))
        .user.id;
}

/**
 * get the account's clientId
 * @return {Number}
 */
export function getClientId() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    return (
        token.user && token.user.clientTeam && token.user.clientTeam.clientId
    );
}

/**
 * get logged in account's associated client
 * @return {Object}
 */
export function getClient() {
    return JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.client`)
    );
}

/**
 * get the accounts contractorId
 * @return {Number}
 */
export function getContractorId() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    return (
        token.user &&
        token.user.contractorTeam &&
        token.user.contractorTeam.contractorId
    );
}

export function getContractorTeamMemberId() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    return (
        token.user &&
        token.user.contractorTeam &&
        token.user.contractorTeam.userId
    );
}

/**
 * get the logged in account's associated contractor
 * @return {Object}
 */
export function getContractor() {
    return JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.contractor`)
    );
}

/**
 * Is current logged in account is able to using direct supply feature
 * @returns {boolean}
 */
export function isDirectSupplyEligible() {
    if (!isContractorAccount()) {
        return false;
    }
    try {
        const contractor = getContractor();
        return !!(
            contractor.directSupply && contractor.directSupplyClients.length > 0
        );
    } catch (error) {
        return false;
    }
}

/**
 * Is current logged in account is dispatchable
 * @returns {boolean}
 */
export function isDispatchable() {
    if (!isContractorAccount()) {
        return false;
    }
    try {
        const contractor = getContractor();
        return !!contractor.dispatchable;
    } catch (error) {
        return false;
    }
}

/**
 * Clear the account cache, by default clears the entire account cache unless optional cache name is provided
 * @param {string} cache the cache to clear (optional)
 * @returns {undefined}
 */
export function clearCache(cache = '') {
    if (cache) {
        localStorage.removeItem(`${Config.storageNameSpace}.${cache}`);
    } else {
        localStorage.removeItem(`${Config.storageNameSpace}.token`);
        localStorage.removeItem(`${Config.storageNameSpace}.client`);
        localStorage.removeItem(`${Config.storageNameSpace}.contractor`);
        localStorage.removeItem(`${Config.storageNameSpace}.acknowledgement`);
    }
}

/**
 * Retrieve an item from the cache as-is or all cache items
 * @param cache
 */
export function getCache(cache) {
    if (cache) {
        return localStorage.getItem(`${Config.storageNameSpace}.${cache}`);
    }
    const _cache = [];
    _cache.push(localStorage.getItem(`${Config.storageNameSpace}.token`));

    const client = localStorage.getItem(`${Config.storageNameSpace}.client`);
    if (client) {
        _cache.push(client);
    }
    const contractor = localStorage.getItem(
        `${Config.storageNameSpace}.contractor`
    );
    if (contractor) {
        _cache.push(contractor);
    }
    return Promise.all(_cache);
}

/**
 *
 * @param {*} roles
 */
export function mostPrivilegedRole(roles) {
    const ownerRE = /_owner$/;
    const adminRE = /_admin$/;
    const managerRE = /_manager$/;
    const userRE = /_user$/;
    const ownerRoles = [];
    const adminRoles = [];
    const managerRoles = [];
    const userRoles = [];
    for (let i = 0; i < roles.length; i += 1) {
        if (ownerRE.test(roles[i].name)) {
            ownerRoles.push(roles[i]);
            break;
        }
        if (adminRE.test(roles[i].name)) {
            adminRoles.push(roles[i]);
            break;
        }
        if (managerRE.test(roles[i].name)) {
            managerRoles.push(roles[i]);
            break;
        }
        if (userRE.test(roles[i].name)) {
            userRoles.push(roles[i]);
            break;
        }
    }
    /**
     * @todo differentiate between these
     */
    return (
        ownerRoles[0] ||
        adminRoles[0] ||
        managerRoles[0] ||
        userRoles[0] ||
        new Error('No known roles specified')
    );
}

/**
 * Update the currently authenticated account
 * @param update
 * @returns {Promise}
 */
export function updateAuthenticatedAccount(update) {
    const _update = assign(
        {},
        {
            email: update.email,
            firstName: update.firstName,
            lastName: update.lastName,
            phone: update.phone,
            phoneCountry: update.phoneCountry,
            timezoneId: update.timezoneId,
            preferences: update.preferences,
            roles: update.roles,
            userType: update.userType,
        }
    );

    const _query = {
        filter: {
            include: [
                {
                    relation: 'preferences',
                    scope: {
                        fields: [
                            'id',
                            'type',
                            'value',
                            'valueOptions',
                            'notification',
                            'primaryAccount',
                            'notificationPreferences',
                        ],
                    },
                },
            ],
        },
    };

    const contractorTeamObj = {
        relation: 'contractorTeam',
        scope: {
            fields: ['id', 'contractorId', 'receivesNotifications'],
            include: [
                {
                    relation: 'contractor',
                    scope: {
                        fields: [
                            'id',
                            'name',
                            'receivesNotifications',
                            'ffScheduler',
                        ],
                    },
                },
            ],
        },
    };

    const clientTeamObj = {
        relation: 'clientTeam',
        scope: {
            fields: ['id', 'clientId'],
            include: [
                {
                    relation: 'client',
                    scope: {
                        fields: ['id', 'name'],
                    },
                },
            ],
        },
    };

    if (update.userType === 'contractor') {
        _query.filter.include.push(contractorTeamObj);
    }
    if (update.userType === 'client') {
        _query.filter.include.push(clientTeamObj);
    }

    return updateAccount(getToken().userId, _update).then(() =>
        getAuthenticatedAccount(getToken().userId, _query)
    );
}

export function patchAuthenticatedAccount(update) {
    const _update = assign(
        {},
        {
            notificationPreferences: update.notificationPreferences,
        }
    );

    const _query = {
        filter: {
            include: [
                {
                    relation: 'preferences',
                    scope: {
                        fields: [
                            'id',
                            'type',
                            'value',
                            'valueOptions',
                            'notification',
                            'primaryAccount',
                            'notificationPreferences',
                        ],
                    },
                },
            ],
        },
    };

    return patchAccount(getToken().userId, _update).then(() =>
        getAuthenticatedAccount(getToken().userId, _query)
    );
}

export function createNewPreferenceForAuthenticatedAccount(preference) {
    const _query = {
        filter: {
            include: [
                {
                    relation: 'preferences',
                    scope: {
                        fields: [
                            'id',
                            'type',
                            'value',
                            'valueOptions',
                            'notification',
                            'primaryAccount',
                            'notificationPreferences',
                        ],
                    },
                },
            ],
        },
    };
    return createPreference(getToken().userId, preference).then(() =>
        getAuthenticatedAccount(getToken().userId, _query)
    );
}

/**
 * Update the currently authenticated account in local cache
 * @param updatedAccount
 * @returns {Promise}
 */
export function updateCachedAuthenticatedAccount(updatedAccount) {
    const tokenCache = getToken();
    clearCache('token');
    // Somewhat onerous; but need to piecemeal out what can be updated to avoid the need to re-auth
    tokenCache.user = assign({}, tokenCache.user, {
        firstName: updatedAccount.firstName,
        lastName: updatedAccount.lastName,
        timezoneId: updatedAccount.timezoneId,
        preferences: updatedAccount.preferences,
        notificationPreferences: updatedAccount.notificationPreferences,
    });
    localStorage.setItem(
        `${Config.storageNameSpace}.token`,
        JSON.stringify(tokenCache)
    );
    return Promise.resolve(updatedAccount);
}

/**
 * Return latest contractor MSA
 * @return {object} acknowledgment
 * @return {string} acknowledgment.type
 * @return {string} acknowledgment.version
 * @return {boolean} acknowledgment.acknowledged
 */
export function latestAcknowledgment() {
    const acknowledgment = localStorage.getItem(
        `${Config.storageNameSpace}.contractorMSA`
    );
    return JSON.parse(acknowledgment);
}

/**
 * Create acknowledgement for user
 * @param {number} accountId
 * @param {object} acknowledgement
 * @param {string} acknowledgement.type
 * @param {string} acknowledgement.version
 * @param {boolean} acknowledgement.acknowledged
 * @return {Promise}
 */
export function acknowledged(accountId, acknowledgement) {
    return createAcknowledgement(accountId, acknowledgement).then(
        _acknowledgement => {
            if (!acknowledgement.acknowledged) {
                clearCache();
                // Reset all stores to initial state
                alt.recycle();
                Raven.setUserContext();
                return _acknowledgement;
            }
            localStorage.setItem(
                `${Config.storageNameSpace}.contractorMSA`,
                JSON.stringify(_acknowledgement)
            );
            return _acknowledgement;
        }
    );
}

export async function extendToken() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    if (token && token.id) {
        const response = await ApiService.get(true, 'accounts/extendToken');
        const updatedToken = {
            ...token,
            created: response.created,
            ttl: response.ttl,
            lastCheckFromBackend: moment().valueOf(),
        };
        localStorage.setItem(
            `${Config.storageNameSpace}.token`,
            JSON.stringify(updatedToken)
        );
    }
}

export async function refreshToken() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    if (token && token.id) {
        if (token.lastCheckFromBackend) {
            const nextCheck = moment(token.lastCheckFromBackend).add(
                Config.tokenValidation.backendInterval,
                'minute'
            );
            if (
                nextCheck &&
                nextCheck.isValid() &&
                nextCheck.isAfter(moment())
            ) {
                return;
            }
        }
        const response = await ApiService.get(true, 'accounts/refreshToken');
        const updatedToken = {
            ...token,
            created: response.created,
            ttl: response.ttl,
            lastCheckFromBackend: moment().valueOf(),
        };
        localStorage.setItem(
            `${Config.storageNameSpace}.token`,
            JSON.stringify(updatedToken)
        );
    }
}

export function getExpireIn() {
    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );
    if (token && token.created && token.ttl) {
        const expireTime = moment.utc(token.created).add(token.ttl, 'second');
        const current = moment.utc();

        return expireTime.diff(current, 'seconds');
    }
    return 0;
}

export function getRedirectPath(baseRoute, redirect) {
    const isValidRoute =
        redirect.includes(baseRoute) ||
        redirect.includes(`${Config.routing.toolsBaseRoute}/`);
    return isValidRoute ? redirect.replace('?redirectTo=', '') : '/';
}
