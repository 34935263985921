/**
 * @license
 * @copyright Copyright Motili Inc., 2023 All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesOrder } from 'common/types/salesOrders';

interface Order {
    property: string;
    sortBy: string;
}
interface State {
    filterId: string;
    activeFilters: any[];
    activePage: number;
    orders: Order[];
    selected: SalesOrder[];
    refresh: number;
}

const initialState: State = {
    activeFilters: [],
    filterId: '',
    activePage: 1,
    orders: [{ property: 'created', sortBy: 'DESC' }],
    selected: [],
    refresh: 0,
};

const adminFinanceInvoiceCreateFilterSlice = createSlice({
    name: 'adminFinanceInvoiceCreateFilter',
    initialState,
    reducers: {
        add: (state, action) => {
            const id = action.payload
                .map((it: any) => it?.id)
                .sort()
                .join('_');
            if (id !== state.filterId) {
                state.activeFilters = action.payload;
                state.filterId = id;
                state.activePage = initialState.activePage;
                state.orders = initialState.orders;
                state.selected = initialState.selected;
            } else {
                state.refresh += 1;
            }
        },
        setActivePage: (state, action: PayloadAction<number>) => {
            state.activePage = action.payload;
        },
        setOrders: (state, action: PayloadAction<Order[]>) => {
            state.orders = action.payload;
        },
        setSelected: (state, action) => ({
            ...state,
            selected: action.payload,
        }),
        init: () => initialState,
    },
});

export const actions = adminFinanceInvoiceCreateFilterSlice.actions;
export default adminFinanceInvoiceCreateFilterSlice.reducer;
