/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

/* eslint-disable class-methods-use-this */

import { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Wrapper from './components/layout/Wrapper';

const Whoops = () => {
    const location = useLocation();
    return (
        <div>
            <Row>
                <Col xs={12} sm={12} md={10} mdOffset={1} lg={10} lgOffset={1}>
                    <h1>Whoops!</h1>
                    <h3>
                        Looks like your looking for a mobile route,
                        unfortunately we haven't migrated our mobile optimized
                        sites yet!. Please contact support@motili.com if you
                        think this is in error.
                        <br />
                        <br />
                        You were looking for: <code>
                            {location.pathname}
                        </code>{' '}
                        but we couldn't find it.
                    </h3>
                </Col>
            </Row>
        </div>
    );
};

export default class MobileRouter extends Component {
    render() {
        return (
            <Wrapper>
                <Whoops />
            </Wrapper>
        );
    }
}
