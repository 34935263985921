import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import Config from 'Config';

// recommended by official docs to only call createApi once per base url
const emptyApi = createApi({
    reducerPath: 'QueryStoreApi',
    baseQuery: fetchBaseQuery({ baseUrl: Config.apiBaseUrl }),
    endpoints: () => ({}),
    tagTypes: [
        'accountDepartmentAssignmentTypes',
        'accountDepartmentTypes',
        'accountPreferenceTypes',
        'accountRoles',
        'accountRoleTypes',
        'accountTypes',
        'assetConfigurationCategories',
        'assetPhotoTypes',
        'assetRetirementReasons',
        'assetStatuses',
        'assetUntaggableReasons',
        'clientBusinessChannels',
        'clientFreightTypes',
        'clientIntegrationTypes',
        'clients',
        'clientSettings',
        'clientStatusChangedNotificationConfig',
        'clientStatuses',
        'contractorBlocklistReasons',
        'contractorExternalCertifications',
        'contractorInternalCertifications',
        'contractorClients',
        'contractorCredentials',
        'contractorFileTypes',
        'contractorQualificationTypes',
        'contractorServiceTypes',
        'contractorStatuses',
        'contractorTeamMemberFileTypes',
        'contractorTeamMemberNotifications',
        'contractorTeamMemberTitles',
        'countries',
        'currencies',
        'documentTradeTypes',
        'documentTypes',
        'draftStatuses',
        'financialBillingStatuses',
        'financialInvoiceLineOfBusinesses',
        'financialPaymentStatuses',
        'financialSalesOrderLineOfBusinesses',
        'financialStatuses',
        'financialSyncQueueModels',
        'financialSyncQueueResolveReasons',
        'financialSyncQueueStatuses',
        'financialTerms',
        'folderAttachmentTypes',
        'managedProductAttributeTypes',
        'managedProductCategories',
        'managedProductPricingTypes',
        'managedVendorDistributionTypes',
        'managedVendorIntegrationTypes',
        'managedVendorStatuses',
        'opsAccounts',
        'orderContactTypes',
        'orderFulfillmentMethods',
        'orderFulfillmentTypes',
        'orderIssues',
        'orderLogisticsTypes',
        'orderNotificationTypes',
        'orderReturnCancelReasons',
        'orderReturnReasons',
        'orderReturnStatuses',
        'orderStatuses',
        'orderTypes',
        'productAttributeTypes',
        'productCategories',
        'projectAttachmentTypes',
        'projectContactTypes',
        'projects',
        'projectScopeTypes',
        'projectStatuses',
        'projectTypes',
        'propertyAttachmentTypes',
        'propertyStatuses',
        'propertyTypes',
        'quoteIssues',
        'quotePdfTypes',
        'quoteReasons',
        'quoteReopenReasons',
        'quoteRequestRevisionTypes',
        'quoteRejectTypes',
        'quoteStatuses',
        'quoteTypes',
        'states',
        'templateTypes',
        'ticketProviders',
        'timezones',
        'unitOfMeasures',
        'usStates',
        'vendorManufacturers',
        'vendorsAll',
        'vendorsApproved',
        'workOrderContactPreferenceTypes',
        'workOrderContactTypes',
        'workOrderIssueRejectedTypes',
        'workOrderIssues',
        'workOrderPdfTypes',
        'workOrderPhotoTypes',
        'workOrderPriorityTypes',
        'workOrderRequirementApprovalReasons',
        'workOrderStatuses',
        'workOrderTypes',
        'assetRecommendationTypes',
        'assetRepairReasons',
        'dataGridState',
    ],
});

export default emptyApi;
