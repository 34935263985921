import { PayloadAction } from '@reduxjs/toolkit';

export const setDefaultAdvancedFilter = (
    state: any,
    action: PayloadAction<any>
) => {
    const filterExists = state.activeFilters.filter(
        (item: any) => item.target === action.payload.target
    );
    if (filterExists.length > 0) {
        state.activeFilters = [...state.activeFilters];
    } else {
        state.activeFilters = [...state.activeFilters, action.payload];
    }
};
export const addAdvanced = (
    state: any,
    action: PayloadAction<{
        filters: any[];
        advancedFilterId?: number;
        updated?: string;
    }>
) => {
    state.activeFilters = [
        ...state.activeFilters.filter((item: any) => item.id < 500),
        ...action.payload.filters,
    ];
    if (action.payload.advancedFilterId) {
        state.advancedFilterId = action.payload.advancedFilterId;
    }
    if (action.payload.updated) {
        state.updated = action.payload.updated;
    }
    state.activePage = 1;
};
export const updateAdvancedFilterId = (
    state: any,
    action: PayloadAction<number>
) => {
    state.advancedFilterId = action.payload;
};
export const remove = (state: any, action: PayloadAction<number>) => {
    state.activeFilters = state.activeFilters.filter(
        (item: any) => item.id !== action.payload
    );
    state.activePage = 1;
};
export const removeAll = (state: any) => {
    state.activeFilters = [];
    state.activePage = 1;
};
export const incrementFilterId = (state: any) => {
    state.filterId++;
};
export const incrementAdvancedFilterId = (state: any) => {
    state.advancedFilterId++;
};

export const add = (state: any, action: PayloadAction<any>) => {
    state.activeFilters = [...state.activeFilters, action.payload];
    state.activePage = 1;
    incrementFilterId(state);
};

export const setOrders = (
    state: any,
    action: PayloadAction<
        { property: string; sortBy: string | null | undefined }[]
    >
) => {
    state.orders = action.payload;
};
export const setActivePage = (state: any, action: PayloadAction<number>) => {
    state.activePage = action.payload;
};
export const setActivePageWithUpdated = (
    state: any,
    action: PayloadAction<{ activePage: number; updated: string }>
) => {
    state.activePage = action.payload.activePage;
    state.updated = action.payload.updated;
};
