/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import {
    forwardRef,
    CSSProperties,
    ReactNode,
    ChangeEvent,
    LegacyRef,
    ChangeEventHandler,
    FocusEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
} from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import classNames from 'classnames';
import { Sizes } from 'react-bootstrap';

import FormValidationDisplay, {
    Validation,
    ValidationState,
} from 'common/components/form/FormValidationDisplay';

import styles from './FormTextInput.module.scss';

export interface FormTextInputProps {
    style?: CSSProperties;
    labelStyle?: CSSProperties;
    className?: string | undefined;
    controlId?: string | undefined;
    name?: string | undefined;
    label?: string | undefined;
    placeholder?: string | undefined;
    validation?: Validation | undefined;
    bsSize?: Sizes | undefined;
    required?: boolean | undefined;
    mask?: string | undefined;
    disabled?: boolean | undefined;
    inputGroupBefore?: ReactNode | undefined;
    inputGroupAfter?: ReactNode | undefined;
    saveUnmasked?: boolean;
    maxLength?: number | undefined;
    value?: string | number | readonly string[] | undefined;
    type?: string | undefined;
    autocomplete?: string | undefined;
    dataTestId?: string | undefined;
    onChange:
        | ChangeEventHandler<HTMLInputElement>
        | FormEventHandler<FormControl>
        | undefined;
    autoFocus?: boolean | undefined;
    onBlur?:
        | FocusEventHandler<FormControl>
        | FocusEventHandler<HTMLInputElement>
        | undefined;
    onKeyPress?:
        | KeyboardEventHandler<HTMLInputElement>
        | KeyboardEventHandler<FormControl>
        | undefined;
}

type Ref = HTMLInputElement;

const FormTextInput = forwardRef<Ref, FormTextInputProps>(
    function FormTextInput(props, ref) {
        return (
            <FormGroup
                style={props.style}
                className={props.className}
                controlId={props.controlId || props.name}
                bsSize={props.bsSize}
                validationState={props.validation?.state}
            >
                {props.label && (
                    <ControlLabel style={props.labelStyle || { fontSize: 12 }}>
                        {props.label}
                    </ControlLabel>
                )}
                {props.required && (
                    <span className={styles.requiredStar}>*</span>
                )}
                <InputGroupConditionalWrapper
                    inputGroupAfter={props.inputGroupAfter}
                    inputGroupBefore={props.inputGroupBefore}
                >
                    {props.inputGroupBefore}
                    {!props.mask && (
                        <FormControl
                            inputRef={
                                ref as
                                    | ((instance: HTMLInputElement) => void)
                                    | undefined
                            }
                            value={props.value}
                            autoFocus={props.autoFocus}
                            disabled={props.disabled}
                            placeholder={props.placeholder}
                            onChange={
                                props.onChange as
                                    | FormEventHandler<FormControl>
                                    | undefined
                            }
                            onKeyPress={
                                props.onKeyPress as
                                    | KeyboardEventHandler<FormControl>
                                    | undefined
                            }
                            onBlur={
                                props.onBlur as
                                    | FocusEventHandler<FormControl>
                                    | undefined
                            }
                            maxLength={props.maxLength}
                            name={props.name}
                            type={props.type}
                            autoComplete={props.autocomplete}
                            data-test-id={props.dataTestId}
                        />
                    )}
                    {props.mask && (
                        <InputMask
                            className={classNames({
                                'form-control': true,
                                [styles.input]: props.disabled,
                            })}
                            style={inputMaskStyle}
                            ref={ref as LegacyRef<InputMask> | undefined}
                            value={props.value}
                            disabled={props.disabled}
                            placeholder={
                                props.placeholder || _getPlaceHolder(props.mask)
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const onChange = props.onChange as
                                    | ChangeEventHandler<HTMLInputElement>
                                    | undefined;
                                if (onChange) {
                                    if (props.saveUnmasked)
                                        onChange(_unMask(e));

                                    onChange(e);
                                }
                            }}
                            onKeyPress={
                                props.onKeyPress as
                                    | KeyboardEventHandler<HTMLInputElement>
                                    | undefined
                            }
                            onBlur={
                                props.onBlur as
                                    | FocusEventHandler<HTMLInputElement>
                                    | undefined
                            }
                            mask={_getMask(props.mask)}
                            name={props.name}
                        />
                    )}
                    {props.inputGroupAfter}
                </InputGroupConditionalWrapper>
                {props.validation?.state && (
                    <FormControl.Feedback>
                        <FormValidationDisplay
                            validation={props.validation}
                            style={{ ...props.style, zIndex: 0 }}
                        />
                    </FormControl.Feedback>
                )}
            </FormGroup>
        );
    }
);

FormTextInput.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    bsSize: PropTypes.oneOf(['sm', 'small', 'lg', 'large']),
    required: PropTypes.bool,
    validation: PropTypes.shape({
        state: PropTypes.oneOf<ValidationState>([
            'error',
            'warning',
            'success',
        ]),
        message: PropTypes.string,
    }),
    controlId: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    inputGroupBefore: PropTypes.element,
    inputGroupAfter: PropTypes.element,
    maxLength: PropTypes.number,
    mask: PropTypes.oneOf(['phone', 'date']),
    saveUnmasked: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    autocomplete: PropTypes.string,
    dataTestId: PropTypes.string,
};

FormTextInput.defaultProps = {
    style: {},
    className: '',
    validation: {
        state: null,
    },
    saveUnmasked: false,
};

export default FormTextInput;

function InputGroupConditionalWrapper(props: {
    inputGroupBefore: ReactNode | undefined;
    inputGroupAfter: ReactNode | undefined;
    children: ReactNode | undefined;
}) {
    if (props.inputGroupBefore || props.inputGroupAfter) {
        return (
            <InputGroup style={{ width: '100%' }}>{props.children}</InputGroup>
        );
    }
    return <span>{props.children}</span>;
}

function _getPlaceHolder(maskName: string) {
    switch (maskName) {
        case 'phone': {
            return '(___) ___-____';
        }
        case 'date': {
            return '__/__';
        }
        default: {
            return undefined;
        }
    }
}

function _getMask(maskName: string) {
    switch (maskName) {
        case 'phone': {
            return '(999) 999-9999';
        }
        case 'date': {
            return '99/99';
        }
        default: {
            return '';
        }
    }
}

function _unMask(e: ChangeEvent<HTMLInputElement>) {
    return {
        ...e,
        target: {
            ...e.target,
            value: e.target.value.replace(/\D/g, ''),
        },
    };
}

const inputMaskStyle = {
    display: 'block',
    width: '100%',
    height: '34px',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.42857',
    color: '#555555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
};
